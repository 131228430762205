import React from 'react';
import './contactus.css';
import { message,Avatar} from 'antd';
import "antd/dist/antd.min.css";
import axios from 'axios';
import { BASE_END_POINT } from "../../urls";
import { connect } from 'react-redux';
import allStrings from "../../assets/strings";
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import { hasValue } from "../../validations/validations";
import { withRouter,Redirect } from "react-router-dom";


class Contactus extends React.Component {
  page = 1;
  pagentationPage=0;
  counter=0;
  state = {
    modal: false,
    confirmDelete: false,
    selectedCategory:null,
    selectedMessage:null,
    messages:[],
    loading:true,
  }

     constructor(props){
      super(props)
      window.scrollTo(0,0)
      if(this.props.isRTL){
        allStrings.setLanguage('ar')
      }else{
        allStrings.setLanguage('en')
      }
    }

     
    //submit form
    flag = -1;
    getMessages= (page,deleteRow) => {
      axios.get(`${BASE_END_POINT}contactUs?page=${page}&limit=20`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
      })
      .then(response=>{
        console.log("ALL messages     ",response.data)
        //console.log(response.data)
        
        this.setState({messages:deleteRow?response.data.data:[...this.state.messages,...response.data.data],loading:false})
      })
      .catch(error=>{
        console.log("ALL messages ERROR")
        console.log(error.response)
        this.setState({loading:false})
      })
    }
    componentDidMount(){
      this.getMessages(1)
      setTimeout(()=>{
        this.getMessages(1,true)
      },1000*60*2)
    }
    

     deleteMessages = (id) => {
       let l = message.loading(allStrings.wait, 2.5)
       axios.delete(`${BASE_END_POINT}/contact-us/${id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${this.props.currentUser.token}`
         },
       })
       .then(response=>{
           l.then(() => message.success(allStrings.done, 2.5))
           this.getMessages(1,true)
           this.flag = -1
       })
       .catch(error=>{
          // console.log(error.response)
           l.then(() => message.error(allStrings.error, 2.5))
       })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            const data = {
              reply:values.replay
            }
            let l = message.loading(allStrings.wait, 2.5)
            console.log("id   ",this.state.selectedMessage)
            axios.post(`${BASE_END_POINT}contact-us/${this.state.selectedMessage.id}/reply`,JSON.stringify(data),{
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props.currentUser.token}`,
                'Accept-Language':this.props.isRTL?'ar':'en'
              },
            })
            .then(response=>{
                l.then(() => message.success(allStrings.replyDone, 2.5));
                this.setState({ modal1Visible:false });
                
            })
            .catch(error=>{
               console.log("message error    ",error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
          }
        }); 
        
      }
      reply = () => {
        const {reply} = this.state
        if(hasValue(reply)){
            let l = message.loading('Wait..', 2.5)
            const data={
                'reply':reply,
            }
            axios.post(`${BASE_END_POINT}contact-us/${this.state.selectedMessage.id}/reply`,JSON.stringify(data),{
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props.currentUser.token}`
              },
            })
            .then(response=>{
                console.log("reply",response.data)
                l.then(() => message.success(allStrings.done, 2.5) )
                this.toggle()
                //this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
     }

      componentDidUpdate(){
        console.log("data   ",this.state.selectedMessage)
      }
      submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.reply()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    toggle = () => {
      this.setState({
        modal: !this.state.modal,
      });
    }
    modal = () =>(
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="12">
                          <div className="md-form">
                            <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.description}</label>
                            <br></br>
                            <textarea style={{direction:this.props.isRTL?'rtl':'ltr',padding:'.3rem 7px .4rem 0'}} type="text" id="form920" className="form-control" onChange={this.changeHandler} required validate  name="reply"/>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon icon="plus" className="mr-2" />
                        {allStrings.reply}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
     );
    render() {
      if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CONTACT-US')) return <Redirect to='/Home' />


      return (
        <div className="App">
        {this.modal()}
          <div className='menu'>
            <div onClick={()=>{              
              this.getMessages(this.page+1)
              this.page=this.page+1
            }} style={{cursor:'pointer', marginTop:30, display:'flex', flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                <span style={{color:'green',fontSize: '18px',fontWeight: '600',padding: '5px'}}>{allStrings.loadMore}</span>
                <MDBIcon icon="plus" className="mr-2" style={{color:'green'}}/>
            </div>
            {this.state.messages.map(val=>
              <div style={{marginTop:10,marginLeft:' 6%'}}>
              <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                <div style={{marginLeft:10}} >
                <Avatar size={70} src='https://img.icons8.com/bubbles/2x/user.png' />
                </div>
                <div style={{marginLeft:10}}>
                  <span style={{fontSize:15}}>{val.name}</span>
                  <br/>
                  <span style={{fontSize:12}}>{val.email}</span>
                  <br/>
                </div>
              </div>
              <div style={{marginLeft:'14%',marginRight:'14%'}}>
                <span style={{color:'red'}} >{val.message}</span>
              </div>
              {val.reply &&
                <div style={{marginLeft:'8.7%',marginRight:'8%'}}>
                  <span>{val.reply?val.replyText:""}</span>
                </div>
              }
              
              <div style={{marginRight:'13%',display:'flex', flexDirection:'row',justifyContent:'flex-end'}}>
                <span style={{color:'red'}} className="material-icons" onClick={()=>{this.deleteMessages(val.id)}}>
                  delete
                </span>
                <span style={{color:'green',cursor:'pointer'}} className="material-icons" onClick={()=>{this.setState({modal:true,selectedMessage:val})}} >
                  reply
                </span>
              </div>
                <hr></hr>
              </div>
            )}
            </div>
        </div>
      );
    }
  }

  const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  })
  
  const mapDispatchToProps = {
  }

  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(Contactus)
  );