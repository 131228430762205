import React from 'react';
import './client info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,TreeSelect} from "antd";
import moment from 'moment';
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class clientInfo extends React.Component {
    state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
      page:1,
      pages:1,
      id:this.props.location.state.data.id,
      clientName:this.props.location.state.data.clientName?this.props.location.state.data.clientName:'',
      clientPhone:this.props.location.state.data.clientPhone?this.props.location.state.data.clientPhone:'',
      clientCountry:this.props.location.state.data.clientCountry?this.props.location.state.data.clientCountry.id:null,
      salesName:this.props.location.state.data.salesName?this.props.location.state.data.salesName:'',
      salesPhone:this.props.location.state.data.salesPhone?this.props.location.state.data.salesPhone:'',
      salesCountry:this.props.location.state.data.salesCountry?this.props.location.state.data.salesCountry.id:null,
      createdAt:this.props.location.state.data.createdAt,
      informClient:this.props.location.state.data.informClient,
      status:this.props.location.state.data.status,
      category:[],
      location:[],
      user:this.props.location.state.data.user,
      notes:this.props.location.state.data.notes?this.props.location.state.data.notes:"",
      locations:[],
      categories:[],
      dealCost:this.props.location.state.data.dealCost,
      model:false
      };

    constructor(props){
        super(props)
        window.scrollTo(0,0)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
      this.getCategory()
      this.getLocations()
      this.getClientData()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getClientData = () => {
      axios.get(`${BASE_END_POINT}clients/${this.state.id}`,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
        }})
      .then(response=>{
          console.log('client  ',response.data.data)
          this.setState({
            loading:false,
            clientName:response.data.data.clientName,
            clientPhone:response.data.data.clientPhone,
            salesName:response.data.data.salesName,
            salesPhone:response.data.data.salesPhone,
            createdAt:response.data.data.createdAt,
            user:response.data.data.user,
            notes:response.data.data.notes?response.data.data.notes:"",
            informClient:response.data.data.informClient,
            status:response.data.data.status,
            dealCost:response.data.data.dealCost,
          })
          if(response.data.data.category){
            let category = []
            response.data.data.category.forEach(v => {
              category.push(v.id)
            });
            this.setState({category:category})
          }
          if(response.data.data.location){
            let location = []
            response.data.data.location.forEach(v => {
              location.push(v.id)
            });
            this.setState({location:location})
          }
      })
      .catch(error=>{
          console.log('theClient ERROR  ',error)
          console.log('theClient ERROR  ',error.respose)
      })
    }
    getCategory = () => {
      axios.get(`${BASE_END_POINT}categories`)
      .then(response=>{
        console.log(response.data)
        this.setState({categories:response.data.data})
      })
      .catch(error=>{
        console.log("ALL categories ERROR")
        console.log(error.response)
      })
    }
    getLocations = () => {
      axios.get(`${BASE_END_POINT}location/withoutPagenation/get`)
      .then(response=>{
        console.log(response.data)
        this.setState({locations:response.data.data})
      })
      .catch(error=>{
        console.log("ALL locations ERROR")
        console.log(error.response)
      })
    }
    update = () => {
        const {clientName,clientPhone,salesName,salesPhone,informClient,category,location} = this.state
        if(hasValue(clientName)&&hasValue(clientPhone)&&hasValue(salesName)&&hasValue(salesPhone)){
          let l = message.loading('Wait..', 2.5)
          const data={
              'clientName':clientName,
              'clientPhone':clientPhone,
              'salesName':salesName,
              'salesPhone':salesPhone,
              'informClient':informClient,
              'category':category,
              'location':location
          }
          axios.put(`${BASE_END_POINT}clients/${this.props.location.state.data.id}`,JSON.stringify(data),{
          headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en',
          },
          })
          .then(response=>{
          console.log("client UPDATED  ",response.data)
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
          })
          .catch(error=>{
              console.log(error.response)
              if(error.response.data.errors.msg[0].msg){
                  l.then(() => message.error(error.response.data.errors.msg[0].msg, 2.5))

              }else{
                  l.then(() => message.error(allStrings.error, 2.5))

              }
          })
        }
    }

    delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}clients/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en',
         },
        })
        .then(response=>{
          console.log("client DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    accept = () => {
        let l = message.loading('Wait..', 2.5)
        axios.put(`${BASE_END_POINT}clients/${this.props.location.state.data.id}/accept`,{},{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en',
        },
        })
        .then(response=>{
          console.log("client accept")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    close = () => {
      let l = message.loading('Wait..', 2.5)
      axios.put(`${BASE_END_POINT}clients/${this.props.location.state.data.id}/close`,{dealCost:this.state.dealCost},{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      },
      })
      .then(response=>{
        console.log("client accept")
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
    }
   
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.update()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    changeHandlerClose = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
    submitHandlerClose = event => {
      event.preventDefault();
      console.log(event.target.className)
      event.target.className += " was-validated";
      this.close()
    };
    modal = () =>(
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerClose}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="12">
                          <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.dealCost}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={0} id="form92" className="form-control" onChange={this.changeHandlerClose} required validate  name="dealCost" defaultValue={this.state.dealCost}/>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                        {allStrings.done}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    toggle = () => {
      this.setState({
          modal: !this.state.modal,
      });
    }
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CLIENTS')) return <Redirect to='/Home' />
  return (
    <div className="App">
        <MDBContainer> 
        {this.modal()}
            <MDBRow>  
                <MDBCol md="12">
                    <div className="infoCard">
                        <Card className="editCard">
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon">
                                <span className="material-icons" style={{color:'white',fontSize:23}}>contact_phone</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'7.5rem',marginRight:this.props.isRTL?'8.5rem':'0'}}>
                            <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    <div className="grey-text">
                                   {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form6015" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form6015"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form6025" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.informClient}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.informClient?allStrings.yes:allStrings.no} type="text" id="form6025"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="VIEW"&&
                                    <MDBRow>
                                        <MDBCol md={this.state.status==="CLOSED"?"6":"12"}>
                                            <div className="md-form">
                                            <label htmlFor="form6005" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.status === "PENDING"?allStrings.pending:this.state.status === "ACCEPTED"?allStrings.accepted:allStrings.closeClient} type="text" id="form6005"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        {this.state.status==="CLOSED"&&
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.dealCost}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.dealCost} type="text" id="form605"  className="form-control" />
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        }
                                        
                                    </MDBRow>
                                    }
                                    
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.clientName}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.clientName} type="text" id="form645" name="clientName" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.clientPhone}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.clientPhone} type="text" id="form56" name="clientPhone" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form516" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.salesName}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.salesName} type="text" id="form516" name="salesName" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form625" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.salesPhone}</label>
                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.salesPhone} type="text" id="form625" name="salesPhone" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    
                                    <MDBRow>
                                      <MDBCol md="6" sm="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.location}</label>
                                          <TreeSelect virtual={false}
                                              showSearch={true}
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {true}
                                              treeNodeFilterProp="title"   
                                              disabled={this.state.actionType==="EDIT"?false:true}                   
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.location}
                                              value={this.state.location}
                                              showCheckedStrategy= {SHOW_PARENT}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({location:value});
                                              }} 
                                          
                                          >
                                              {this.state.locations.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                      <MDBCol md="6" sm="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                                          <TreeSelect virtual={false}
                                              showSearch={false}
                                              disabled={this.state.actionType==="EDIT"?false:true} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {true}         
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.category}
                                              value={this.state.category}
                                              showCheckedStrategy= {SHOW_PARENT}
                                              onChange={(value) => {
                                              console.log(value)
                                              this.setState({category:value});
                                              }} 
                                          
                                          >
                                              {this.state.categories.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                      </MDBCol>
                                    
                                    </MDBRow>
                                    {/* <MDBRow>
                                        <MDBCol md="12">
                                            <div class="md-form">
                                            <label for="form9220" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.notes}</label>
                                            <br></br>
                                            <textarea disabled={this.state.actionType==="EDIT"?false:true}  value={this.state.notes} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 0'}} type="text" id="form9220" class="form-control"  validate  name="notes"/>
                                            </div>
                                        </MDBCol>
                                    </MDBRow> */}
                                    {this.state.actionType==="VIEW"&& this.state.createdAt&&
                                    <MDBRow>
                                        <MDBCol md="12">
                                            <div className="md-form">
                                            <label htmlFor="form062" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                                            <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={moment(this.state.createdAt).format('lll')} type="text"  id="form062"  className="form-control"  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        
                                    </MDBRow>
                                    }
                                    {this.state.actionType==="VIEW"&&
                                      <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                          <MDBCol md="10" xs="10">
                                              <div class="md-form">
                                              <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.addedBy}</label>
                                              <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user?this.state.user.fullname:''} type="text" id="form80" class="form-control"  />
                                              </div>
                                          </MDBCol>
                                          <MDBCol md="2" xs="2">
                                        
                                          <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                              <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                              onClick={()=>{this.props.history.push('userInfo',{data:this.state.user,actionType:'VIEW'}) }}>
                                                remove_red_eye
                                              </span>
                                              <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                                onClick={()=>{this.props.history.push('userInfo',{data:this.state.user}) }}>
                                                {allStrings.show}
                                              </span> 
                                            </p>
                                          </MDBCol>
                                      </MDBRow>
                                    }
                                    

                                    
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                      {this.state.actionType==="EDIT"&&
                                        <MDBBtn
                                        style={{background:'#E6B659',color:'#fff'}}
                                        rounded
                                        color="#E6B659"
                                        type="submit"
                                        className="btn-block z-depth-1a"
                                        >
                                            <MDBIcon icon="edit" className="mr-2" />
                                            {allStrings.update}          
                                        </MDBBtn>
                                      }{this.state.actionType==="VIEW"&&this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                            {allStrings.remove}
                                        </MDBBtn>
                                      }
                                      {this.state.status ==="PENDING"&&this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.accept()}} rounded color="green" className="btn-block z-depth-1a">
                                            <MDBIcon  className="mr-2" />
                                            {allStrings.accept}
                                        </MDBBtn>
                                      }
                                      {this.state.status ==="ACCEPTED"&&this.state.actionType==="VIEW"&&
                                        <MDBBtn onClick={()=>{this.toggle()}} rounded  className="btn-block z-depth-1a">
                                            <MDBIcon  className="mr-2" />
                                            {allStrings.closeClient}
                                        </MDBBtn>
                                      }
                                        
                                    </div>
                                </form>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
            
      </MDBContainer>
      
    </div> 
  )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(clientInfo)
  );