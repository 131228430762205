import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './newLaunches.css'
import {message,TreeSelect} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";
//import 'moment/locale/ar';
import {SearchOutlined} from '@ant-design/icons';

const { TreeNode } = TreeSelect;
let headers = [
  { label: "#", key: "id" },
  { label: "ArabicName", key: "arabicName" },
  { label: "EnglishName", key: "englishName" },
  { label: "Company", key: "company" },
  { label: "Commission", key: "commission" },
];
 
let csvData = [
  
];
class newLaunches extends React.Component {
  
  state={
    newLaunches:[],
    newLaunches2:[],
    categories:[],
    locations:[],
    csvData:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    collapsed: false,
    search:null,
    company:null,
    filterType:{},
    value:'',
    companies:[]
  }

  constructor(props){
    super(props)
    //this.getNewLaunchessWithoutPagenation()
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    let pageNum = this.state.page
    if(sessionStorage.getItem('pageName') === "NEW-LANCHES"){
      pageNum = sessionStorage.getItem('pageNum');
      this.setState({page:pageNum})
    }
    this.getNewLaunches(pageNum)
    this.getCompany()

    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getCompany = () => {
    axios.get(`${BASE_END_POINT}companies/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data)
      this.setState({companies:response.data.data})
    })
    .catch(error=>{
      console.log("ALL company ERROR")
      console.log(error.response)
    })
  }
  getNewLaunches = (page,rest) =>{
    this.setState({loading:true})
    let url = `${BASE_END_POINT}newLaunches?page=${page}`
    let {search,company} = this.state
    if(company !== null){
      let str = '&company='+ company
      url += str
    }
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    if(rest) url = `${BASE_END_POINT}newLaunches?page=${page}`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("newLaunches   ",response.data)
      var data = response.data.data.map(item=>[
        item.id,
        item.imgs?{type:"img",img:item.imgs[0]}:{type:"img",img:""},
        item.name_ar,
        item.name_en,
        item.company?item.company.name :'',
        item.commission,
        "action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        newLaunches:response.data.data,
        newLaunches2:data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
      sessionStorage.setItem('pageNum', page);
      sessionStorage.setItem('pageName', "NEW-LANCHES");
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getNewLaunchessWithoutPagenation = (rest) =>{
    let url = `${BASE_END_POINT}newLaunches/withoutPagenation/get`
    let {search,company} = this.state
    if(company !== null){
      let str = '&company='+ company
      url += str
    }
    if(search !== null){
      let str = '&search='+ search
      url += str
    }
    if(rest) url = `${BASE_END_POINT}newLaunches/withoutPagenation/get`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("newLaunches   ",response.data)
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{arabicName:item.name_ar},
            ...{englishName:item.name_en},
            ...{arabicName:item.name_ar},
            ...{englishName:item.name_en},
            ...{company:item.company?item.company.name :''},
            ...{commission:item.commission},
          };
        });
        console.log(csvData)
        this.setState({csvData:csvData})
      
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }
  delete = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}newLaunches/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
       'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("Row DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getNewLaunches(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="addNewLaunch">
        <Fab
          style={{background:'#233b5b',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
      
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{dACCEPTEDlay: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getNewLaunches(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getNewLaunches(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getNewLaunches(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} 
        style={{color:`#233b5b !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getNewLaunches(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getNewLaunches(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('NEW-LAUNCHES')) return <Redirect to='/Home' />

    return (
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          
          <Table
            title={allStrings.newLaunches}
            icon='location_city'
            
            settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.setState({search:null,company:null});this.getNewLaunches(1,true)/*;this.getNewLaunchessWithoutPagenation(true)*/}}
                class="material-icons">
                  replay
                </span>
                {/* <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"newLaunches.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img crossorigin="anonymous"alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink> */}
              </div>
            }
            filter ={
              <div>
                <div className={this.state.showFilter?'showClassC':'hideClass'}>
                  <MDBRow>
                    
                    <MDBCol xl="6" lg="6" md="6" sm="6">
                      <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.company}</label>
                      <TreeSelect virtual={false}
                            showSearch={true}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.company}
                            onChange={(value) => {
                              console.log(value)
                              this.setState({company:value});
                            }} 
                        
                        >
                            {this.state.companies.map(val=>(
                                <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                            ))}
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol xl="6" lg="6" md="6" sm="6">
                      <div class="md-form">
                      <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',fontWeight:'300'}} type="text" id="form605"  class="form-control"
                        placeholder={allStrings.Search}
                        onChange={(e)=>{
                          this.setState({search:e.target.value})
                        }}  
                      />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>

                  <Button style={{background: '#233b5b',color: '#fff',width: '100%',margin: '32px auto'}} 
                        onClick={()=>{this.getNewLaunches(1)/*;this.getNewLaunchessWithoutPagenation()*/}}><SearchOutlined /> {allStrings.Search}</Button>
                    
                  </MDBRow>
                  
                </div>
              </div>
            }
            data={this.state.newLaunches}
            data2={this.state.newLaunches2}
            tableColumns={["#",allStrings.logo,allStrings.arabicName,allStrings.englishName,allStrings.company,allStrings.commission,allStrings.action]} loading={this.state.loading}
            deleteButton={(id)=>{this.delete(id)}}
            path='/newLaunchInfo'
            actionIndex={8}
            />
            {this.paginationButtons()}
            {this.addNewRecordButton()}
              
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
    );
  }

}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDACCEPTEDatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDACCEPTEDatchToProps)(newLaunches)
);

