import React from 'react';
import './newLaunch info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Image ,TreeSelect} from "antd";
import { UploadOutlined } from '@ant-design/icons';
const { TreeNode } = TreeSelect;

class newLaunchInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        name_en:this.props.location.state.data.name_en,
        name_ar:this.props.location.state.data.name_ar,
        description_ar:this.props.location.state.data.description_ar,
        description_en:this.props.location.state.data.description_en,
        commission:this.props.location.state.data.commission,
        salesName:this.props.location.state.data.salesName?this.props.location.state.data.salesName:'',
        salesPhone:this.props.location.state.data.salesPhone?this.props.location.state.data.salesPhone:'',
        salesCountry:this.props.location.state.data.salesCountry?this.props.location.state.data.salesCountry.id:null,
        
        imgs:this.props.location.state.data.imgs?this.props.location.state.data.imgs:null,
        allImgs:this.props.location.state.data.imgs?this.props.location.state.data.imgs:[],
        imgsPreviewUrl:this.props.location.state.data.imgs?this.props.location.state.data.imgs:[],
        imgsName:[],
        imgsType:'url',
        theFeatures:[],
        companies:[],
        company:this.props.location.state.data.company?this.props.location.state.data.company.id:null,
        locations:[],
        location:this.props.location.state.data.location?this.props.location.state.data.location.id:null,
        categories:[],
        countries:[]
    };

    constructor(props){
        super(props)
        this.getCompanies();
        this.getLocations();
        this.getCategory();
        this.getCountries()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    componentDidMount(){
        this.getData()
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    getData = () => {
        axios.get(`${BASE_END_POINT}newLaunches/${this.state.id}`,{
          headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en',
          }})
        .then(response=>{
            console.log('client  ',response.data.data)
            this.setState({
              loading:false,
              salesName:response.data.data.salesName,
              salesPhone:response.data.data.salesPhone,
              salesCountry:response.data.data.salesCountry.id,
              createdAt:response.data.data.createdAt,
              name_ar:response.data.data.name_ar,
              name_en:response.data.data.name_en,
              description_ar:response.data.data.description_ar,
              description_en:response.data.data.description_en,
              company:response.data.data.company.id,
              location:response.data.data.location.id,
              commission:response.data.data.commission,

            })
            let theFeatures = []
            response.data.data.features.forEach(element => {
                theFeatures.push({
                    category:element.category.id,
                    price:element.price,
                    bookingPrice:element.bookingPrice
                })
            });
            if(theFeatures.length === 0){
                theFeatures = [{category:null,price:0,bookingPrice:0}]
            }
            this.setState({theFeatures: theFeatures})
        })
        .catch(error=>{
            console.log('theClient ERROR  ',error)
            console.log('theClient ERROR  ',error.respose)
        })
    }
    getCategory = () => {
        axios.get(`${BASE_END_POINT}categories`)
        .then(response=>{
          console.log(response.data)
          this.setState({categories:response.data.data})
        })
        .catch(error=>{
          console.log("ALL categories ERROR")
          console.log(error.response)
        })
    }
    getCompanies = () => {
        axios.get(`${BASE_END_POINT}companies/withoutPagenation/get`)
        .then(response=>{
          console.log(response.data)
          this.setState({companies:response.data.data})
        })
        .catch(error=>{
          console.log("ALL companies ERROR")
          console.log(error.response)
        })
    }
    getLocations = () => {
        axios.get(`${BASE_END_POINT}location/withoutPagenation/get`)
        .then(response=>{
          console.log(response.data)
          this.setState({locations:response.data.data})
        })
        .catch(error=>{
          console.log("ALL locations ERROR")
          console.log(error.response)
        })
    }
    getCountries = () => {
        axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization':`Bearer ${this.props.currentUser.token}`
            },
           })
        .then(response=>{
          console.log("countries",response.data)
          this.setState({countries:response.data.data})
        })
        .catch(error=>{
          console.log("ALL countries ERROR")
          console.log(error.response)
        })
    }
    update = () => {
        const {name_en,name_ar,description_en,description_ar,commission,imgs,imgsType,
        company,salesName,salesPhone,salesCountry,location} = this.state
        if(hasValue(name_ar)&&hasValue(name_en)&&hasValue(description_ar)&&hasValue(description_en)
        &&hasValue(salesName)&&hasValue(salesPhone)&&company !=null&&salesCountry!==null
        ){
            let l = message.loading('Wait..', 2.5)
            var data = new FormData()
            data.append('name_ar',name_ar)
            data.append('name_en',name_en)
            data.append('description_en',description_en)
            data.append('description_ar',description_ar)
            data.append('commission',commission)
            data.append('company',company)
            data.append('location',location)
            data.append('salesName',salesName)
            data.append('salesPhone',salesPhone)
            data.append('salesCountry',salesCountry)
            if(imgs != null && imgsType){
                for(var i=0 ; i<= imgs.length-1 ; i++){
                  data.append(`imgs`,imgs[i] )
                } 
            }
            let features = [...new Set(this.state.theFeatures.filter(e => e.category != null))]
            data.append('features',JSON.stringify(features))
            axios.put(`${BASE_END_POINT}newLaunches/${this.props.location.state.data.id}`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
                console.log("newLaunch UPDATED  ",response.data)
                l.then(() => message.success(allStrings.done, 2.5) )
                this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
      
    }

    delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}newLaunches/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
    fileChangedHandler = (event) => {
        let imgs =[];
        let imgsPreviewUrl = [];
        let imgsName = []
        for(var i=0 ; i<= event.target.files.length-1 ; i++){
            imgsPreviewUrl.push(URL.createObjectURL(event.target.files[i]))
            imgsName.push(event.target.files[i].name)
        }
        imgs.push(event.target.files)
        this.setState({
            imgs: event.target.files,
            imgsType:'data',
            imgsPreviewUrl:imgsPreviewUrl,
            imgsName:imgsName
        });
    };
    removeFile = (url) => {
        let arr = this.state.imgsPreviewUrl;
        let arr2 =  Array.from(this.state.imgs);
        var index = arr.indexOf(url);
        if (index !== -1) arr.splice(index, 1);
        if (index !== -1) arr2.splice(index, 1);
        this.setState({
            imgsPreviewUrl: arr,
            imgs:arr2,
        });
        if(arr2.length === 0){
            this.setState({
            imgs:null,
            });
        }

    };
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.update()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    imageSection = () =>(
        <MDBCol xl="4" lg="5" md="12" sm="12">
          <div className="infoCard" style={{marginTop:'80px'}}>
            <div className="StoreSlider">
              <div style={{height: '280px',overflow: 'hidden'}}>
                <Image.PreviewGroup >
                  {this.state.allImgs.map((val,i)=>
                    <Image placeholder={true} width="100%" height="100%" style={{display: i===0?"block":"none",borderRadius:'6px'}}   src={val} />
                  )}
                </Image.PreviewGroup>
              </div>
            </div>
          </div>
        </MDBCol>
    ) 
    theFeatures(){
        return this.state.theFeatures.map((val, i) =>
            <MDBContainer> 
              <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}> 
                <MDBCol xl="4" lg="4" md="4" sm="12">
                    <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.category}</label>
                    <TreeSelect virtual={false}
                        showSearch={true}
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                        treeCheckable = {false}         
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.category}
                        value={val.category}
                        disabled={this.state.actionType==="VIEW"?true:false}  
                        onChange={(value) => {
                            console.log(value)
                            let theFeatures = [...this.state.theFeatures];
                            theFeatures[i].category = value;
                            this.setState({ theFeatures });
                        }} 
                        
                    
                    >
                        {this.state.categories.map(val=>(
                            <TreeNode value={val.id} title={this.props.isRTL?val.categoryName_ar:val.categoryName_en} key={val.id} />
                        ))}
                    </TreeSelect>
                </MDBCol>
                <MDBCol md="3">
                    <div className="md-form">
                    <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.price}</label>
                    <input value={val.price} min={0} step="0.01" style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                    type="number" id="form2"  className="form-control" 
                    disabled={this.state.actionType==="VIEW"?true:false}
                    onChange={(event)=>{
                        let theFeatures = [...this.state.theFeatures];
                        theFeatures[i].price = event.target.value;
                        console.log("theFeatures",theFeatures)
                        this.setState({ theFeatures });
                    }} required validate/>
                    <div class="valid-feedback">{allStrings.correct}</div>
                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                    </div>
                </MDBCol>
                
                <MDBCol md="3">
                    <div className="md-form">
                    <label htmlFor="form2" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.bookingPrice}</label>
                    <input value={val.bookingPrice} min={0} step="0.01" style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} 
                    type="number" id="form2" className="form-control" 
                    disabled={this.state.actionType==="VIEW"?true:false}
                    onChange={(event)=>{
                        let theFeatures = [...this.state.theFeatures];
                        theFeatures[i].bookingPrice = event.target.value;
                        console.log("theFeatures",theFeatures)
                        this.setState({ theFeatures });
                    }} required validate/>
                    <div class="valid-feedback">{allStrings.correct}</div>
                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                    </div>
                </MDBCol>
                {this.state.actionType === "EDIT"&&
                <MDBCol md="2">
                    {i>0&& 
                    <div className="minusInput" onClick={()=>this.removeFeaturesInput(i)}>
                    <MDBIcon icon="trash" className="mr-2" />           
                    </div>
                    }
                    {i===this.state.theFeatures.length-1&& 
                    <div className="addInputInner"  onClick={()=>this.setState(prevState => ({ theFeatures: [...prevState.theFeatures, {category:null, price:0,bookingPrice:0}]})) }>
                    <MDBIcon icon="plus" className="mr-2" />           
                    </div>
                    }
                </MDBCol>
                }
              </MDBRow>
            </MDBContainer>   
        )
    }
    removeFeaturesInput = (i) =>{
        let theFeatures = [...this.state.theFeatures];
        console.log(i)
        theFeatures.splice(i,1);
        console.log("theFeatures",theFeatures)
        this.setState({ theFeatures });
    } 
    render(){
        if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('NEW-LAUNCHES')) return <Redirect to='/Home' />

        return (
            <div className="App">
                <MDBContainer> 
                    <MDBRow>  
                        <MDBCol xl ={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
                            <div className="infoCard">
                                <Card className="editCard">
                                    <CardMedia
                                    style={{height:"0px"}}
                                    />
                                    <CardHeader></CardHeader>
                                    <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                        <div className="topIcon">
                                            <span class="material-icons" style={{color:'white',fontSize:23}}>business</span>
                                        </div>
                                        <div className="tableTitle"  style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                        marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                                        <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                                        </div>
                                    </div>
                                    <CardContent style={{minHeight:"390px",outline:'none'}}>
                                        <MDBContainer>
                                            <MDBRow>
                                                <MDBCol md="12">
                                                <form
                                                className="needs-validation"
                                                onSubmit={this.submitHandler}
                                                noValidate
                                                >
                                                    
                                                    <div className="grey-text">
                                                    {this.state.actionType==="VIEW"&&
                                                    <MDBRow>
                                                        <MDBCol md="12">
                                                            <div className="md-form">
                                                                <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.id} type="text" id="form8" className="form-control"  validate/>
                                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    }
                                                    <MDBRow>
                                                        <MDBCol md="6">
                                                            <div class="md-form">
                                                            <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9\s]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" class="form-control" onChange={this.changeHandler} required  validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div class="md-form">
                                                            <label for="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" class="form-control" onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md="12">
                                                            <div className="md-form">
                                                            <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishDescription}</label>
                                                            <br></br>
                                                            <textarea value={this.state.description_en} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9-,.@%$*&!_\s]/g,'') }  name="description_en" onChange={this.changeHandler} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate   />
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md="12">
                                                            <div className="md-form">
                                                            <label htmlFor="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicDescription}</label>
                                                            <br></br>
                                                            <textarea value={this.state.description_ar} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } name="description_ar" onChange={this.changeHandler} style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} type="text" id="form920" className="form-control" required validate   />
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                                        <MDBCol md="12">
                                                            <div className="md-form">
                                                            <label htmlFor="formn8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.commission}</label>
                                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.commission} type="number" min={0} id="formn8" className="form-control" name='commission' onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>

                                                        <MDBCol xl="6">
                                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.location}</label>
                                                            <TreeSelect virtual={false}
                                                                showSearch={true}
                                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                                treeCheckable = {false}         
                                                                treeNodeFilterProp="title"                      
                                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                placeholder={allStrings.location}
                                                                value={this.state.location}
                                                                disabled={this.state.actionType==="VIEW"?true:false}  
                                                                onChange={(value) => {
                                                                console.log(value)
                                                                this.setState({location:value});
                                                                }} 
                                                            
                                                            >
                                                                {this.state.locations.map(val=>(
                                                                    <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                                                ))}
                                                            </TreeSelect>
                                                        
                                                        </MDBCol>
                                                        <MDBCol xl="6">
                                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.company}</label>
                                                            <TreeSelect virtual={false}
                                                                showSearch={true}
                                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                                treeCheckable = {false}         
                                                                treeNodeFilterProp="title"                      
                                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                                placeholder={allStrings.company}
                                                                value={this.state.company}
                                                                disabled={this.state.actionType==="VIEW"?true:false}  
                                                                onChange={(value) => {
                                                                console.log(value)
                                                                this.setState({company:value});
                                                                }} 
                                                            
                                                            >
                                                                {this.state.companies.map(val=>(
                                                                    <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                                                ))}
                                                            </TreeSelect>
                                                        
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                            <label htmlFor="form516" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.salesName}</label>
                                                            <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.salesName} type="text" id="form516" name="salesName" className="form-control" onChange={this.changeHandler} required validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol md="6">
                                                            <div className="md-form">
                                                            <label htmlFor="form625" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.salesPhone}</label>
                                                            <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.salesPhone} type="text" id="form625" name="salesPhone" className="form-control" onChange={this.changeHandler} required  validate/>
                                                            <div class="valid-feedback">{allStrings.correct}</div>
                                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                                        <MDBCol md="6"></MDBCol>
                                                        <MDBCol md="6">
                                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                                                            <TreeSelect
                                                            value= {this.state.salesCountry}
                                                            showSearch={true} 
                                                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                            treeCheckable = {false}    
                                                            disabled={this.state.actionType==="VIEW"?true:false}     
                                                            treeNodeFilterProp="title"                      
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}

                                                            placeholder={allStrings.country}
                                                            onChange={(value) => {
                                                                this.setState({salesCountry:value});
                                                                console.log(value)
                                                            }} 
                                                            
                                                            >
                                                                {this.state.countries.map(val=>(
                                                                    <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                                                ))}
                                                            </TreeSelect>
                                                        </MDBCol>
                                                        
                                                    </MDBRow>
                                                    <label htmlFor="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%',marginBottom:'25px',marginTop:'25px'}}>
                                                        <span style={{padding:'10px',background:'#233b5b',borderBottomLeftRadius: '14px',color:'#fff',marginTop:'25px'}}>{allStrings.Features}</span>
                                                    </label>
                                                    {this.theFeatures()}
                                                    
                                                    {this.state.actionType ==="EDIT"&&
                                                        <MDBRow>
                                                            <MDBCol md="12">
                                                            <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.imgs}</label>

                                                                <div className="fileUpload2"> 
                                                                    <input multiple key={this.state.imgs} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                                    <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                                </div>
                                                                <div className="preview">
                                                                {this.state.imgsPreviewUrl.map((val,i) => (
                                                                <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine' ,marginBottom:'10px'}}>
                                                                    <img crossorigin="anonymous"alt="" src={this.state.imgsType==="data"?URL.createObjectURL(this.state.imgs[i]):this.state.imgs[i]} className="previewImg2"/>
                                                                    <span className="imgName">{this.state.imgsName[i]}</span>
                                                                    <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                                    onClick={() => this.removeFile(val)}  ></MDBIcon>
                                                                </div>
                                                                ))}
                                                                </div>
                                                                
                                                            </MDBCol>
                                                        </MDBRow>
                                                    }
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <div className="text-center">
                                                        {this.state.actionType==="EDIT"&&
                                                        <MDBBtn
                                                        style={{background:'#233b5b',color:'#fff'}}
                                                        rounded
                                                        type="submit"
                                                        className="btn-block z-depth-1a"
                                                        >
                                                            <MDBIcon icon="edit" className="mr-2" />
                                                            {allStrings.update}          
                                                        </MDBBtn>
                                                        }{this.state.actionType==="VIEW"&&
                                                        <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                                                            <MDBIcon icon="trash-alt" className="mr-2" />
                                                            {allStrings.remove}
                                                        </MDBBtn>
                                                        }
                                                        
                                                    </div>
                                                </form>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBContainer>
                                    </CardContent>
                                </Card>
                            </div>
                        </MDBCol>
                        {this.state.actionType==="VIEW"&&this.imageSection()}   
                    </MDBRow>
                </MDBContainer>
            
            </div> 
        )
    }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(newLaunchInfo)
);