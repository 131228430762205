import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './categories.css'
import {message} from 'antd';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {NavLink, withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'


class Categories extends React.Component {
  
  state={
    categories:[],
    categories2:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
  }

  constructor(props){
    super(props)
    if(this.props.isRTL){
      window.scrollTo(0,0)
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    this.getCategories(1,false)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }


  getCategories = (page,refresh) =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}categories/pagenation-categories?page=${page}`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("categories   ",response.data)
      var data = response.data.data.map(item=>[
        item.id,
        item.categoryName_ar,
        item.categoryName_en,
        "action"])
      console.log("DATA   ",data)
      this.setState({
        loading:false,
        refresh:false,
        categories:response.data.data,
        categories2:data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }

  delete = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}categories/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getCategories(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
 }

  addNewRecordButton = () =>(
    <div style={{width:'100%'}}>
      <NavLink to="addCategory">
        <Fab
          style={{background:'#233b5b',float:'left',margin:'50px 30px'}}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
        >
          <AddIcon />{allStrings.addNewRecored}
        </Fab>
      </NavLink>
       
    </div>
  )

  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getCategories(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getCategories(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getCategories(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} style={{color:`#233b5b !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getCategories(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getCategories(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )

  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('CATEGORIES')) return <Redirect to='/Home' />

  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          <Table
            title={allStrings.categoriesTable}
            icon='category'
            data={this.state.categories}
            data2={this.state.categories2}
            tableColumns={["#",allStrings.arabicName,allStrings.englishName,allStrings.action]}
            loading={this.state.loading}
            deleteButton={(id)=>{this.delete(id)}}
            path='/CategoryInfo'
            actionIndex={3}
            />
            {this.paginationButtons()}
            {this.addNewRecordButton()}
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer >
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Categories)
);

