import React from "react";
import "./forgetPassword.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message} from 'antd';

class ForgetPassword extends React.Component {
  state = {
    password: "",
    step:"chooseWay",
    phone:"",
    email:"",
    confirmPassword:'',
    code:'',
    forgetWay:"Email"
  };

  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  emailStep1 = () =>{
    this.setState({step:"emailStep1",forgetWay:'Email'})
  }
  step2 = () =>{
    this.setState({step:"step2"})
  }
  step3 = () =>{
    this.setState({step:"step3"})
  }
  phoneStep1 = () =>{
    this.setState({step:"phoneStep1",forgetWay:'Phone'})
  }
  sendCodeEmail = () =>{
    const {email} = this.state
    console.log(email)

    if(hasValue(""+email)){
      let l = message.loading(allStrings.wait, 2.5)
      axios.post(`${BASE_END_POINT}sendCode`, JSON.stringify({email:email.toLowerCase()}), {
        headers: {
           'Content-Type':  'application/json',
           'Accept-Language':this.props.isRTL?'ar':'en'
         },
       })
       .then(response=>{
        console.log("response",response)
        l.then(() => message.success(allStrings.done, 2.5) )
         this.setState({step:"step2"})
       })
       .catch(error=>{
           console.log("ERROR P1 ",error.response)
          if (error.request.status ===  400) {
            l.then(() => message.error(allStrings.enterCorrectEmail, 2.5))
          }
       })
    }else{
      console.log("NO VALUES")
    }
  }
  sendCodePhone = () =>{
    const {phone} = this.state
    console.log(phone)
    if(hasValue(""+phone)){
      let l = message.loading(allStrings.wait, 2.5)
      axios.post(`${BASE_END_POINT}sendCode-phone`, JSON.stringify({phone}), {
        headers: { 
          'Content-Type':  'application/json',
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
       })
       .then(response=>{
        console.log("response ",response)
        l.then(() => message.success(allStrings.done, 2.5) )
        this.setState({step:"step2"})

       })
       .catch(error=>{
           console.log("ERROR P1 ",error.response)
          if (error.request.status ===  400) {
            l.then(() => message.error(allStrings.enterCorrectPhone, 2.5))
          }
       })
    }else{
      console.log("NO VALUES")
    }
  }
  verifyCode = () =>{
    const {code,email,phone,forgetWay} = this.state
    console.log("CODE  :"+code)
    if(hasValue(""+code)){
      let l = message.loading(allStrings.wait, 2.5)
      if(forgetWay === "Phone"){
        axios.post(`${BASE_END_POINT}confirm-code-phone`, JSON.stringify({
          phone:phone,
          verifycode:code,
        }), {
          headers: { 
            'Content-Type':  'application/json',
            'Accept-Language':this.props.isRTL?'ar':'en'
         },
         })
         .then(response=>{
          l.then(() => message.success(allStrings.done, 2.5) )
           this.setState({step:"step3"})
         })
         .catch(error=>{
             console.log("ERROR P2 ",error.response)
            if (error.request.status ===  400) {
              l.then(() => message.error(allStrings.correctCode, 2.5))
            }
         })
      } else{
        axios.post(`${BASE_END_POINT}confirm-code`, JSON.stringify({
          email:email,
          verifycode:code,
        }), {
          headers: { 
            'Content-Type':  'application/json', 
            'Accept-Language':this.props.isRTL?'ar':'en'
          },
         })
         .then(response=>{
          l.then(() => message.success(allStrings.done, 2.5) )
           this.setState({step:"step3"})
         })
         .catch(error=>{
             console.log("ERROR P2 ",error.response)
            if (error.request.status ===  400) {
              l.then(() => message.error(allStrings.correctCode, 2.5))
            }
         })
      }
     
    }else{
      console.log("NO VALUES")
    }
  }
  updatePassword = () =>{
    const {email,password,confirmPassword,phone,forgetWay} = this.state
    console.log("PASSWORD  "+password+"    /   CONFIRM     "+confirmPassword)
    if(hasValue(""+password)&&hasValue(""+confirmPassword)){
      if(password===confirmPassword){
        let l = message.loading(allStrings.wait, 2.5)
        if(forgetWay ==="Phone"){
          axios.post(`${BASE_END_POINT}reset-password-phone`, JSON.stringify({
            phone:phone,
            newPassword:password,
          }), {
            headers: { 
              'Content-Type':  'application/json',
              'Accept-Language':this.props.isRTL?'ar':'en'
           },
          })
          .then(response=>{
              console.log("DONE P3")
              l.then(() => message.success(allStrings.done, 2.5) )
              this.props.history.replace('/Login') 
          })
          .catch(error=>{
              console.log("ERROR P3 ",error.response)
              l.then(() => message.error('active', 2.5))
          })
        } else{
          axios.post(`${BASE_END_POINT}reset-password`, JSON.stringify({
            email:email.toLowerCase(),
            newPassword:password,
          }), {
            headers: { 'Content-Type':  'application/json', },
          })
          .then(response=>{
              console.log("DONE P3")
              l.then(() => message.success(allStrings.done, 2.5) )
              this.props.history.replace('/Login') 
          })
          .catch(error=>{
              console.log("ERROR P3 ",error.response)
              l.then(() => message.error('active', 2.5))
          })
        }
       
      }else{
        message.warning(allStrings.passwordMustEqualConfirmPassword);
      }
    }else{
      console.log("NO VALUES OF PAGE 3")
    }
  }


  submitHandler1 = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.sendCodeEmail()
  };
  submitHandler2 = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.sendCodePhone()
  };
  submitHandlerPage2 = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.verifyCode()
  }
  submitHandlerPage3 = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updatePassword()
  }
  changeHandler1 = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  changeHandler2 = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  changeHandler3 = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  changeHandler4 = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    console.log(this.state.step)
    return (
      <div className="login">
        <MDBContainer className="loginContainer">
          <MDBRow>
            <MDBCol  xl="6" lg="6" md="4" sm="12" className="noPadding">
              <div className="loginLeft">

              </div>
            </MDBCol>
            <MDBCol  xl="6" lg="6" md="8" sm="12">
              <div className="loginRight">
                <div className="logoDiv">
                  <img crossorigin="anonymous"alt="" style={{
                      margin: 'auto',
                      display: 'block',
                      marginTop: '4rem'
                  }} width="120px" src={require('../../assets/images/logo.png')}></img>
                </div>
                <br></br>
                <div className="formContainer">
                  {this.state.step === "chooseWay" &&
                  <MDBContainer>
                   
                  <MDBRow>
                    <MDBCol md="6" >
                      <div className="chooseForget" onClick={this.emailStep1}>
                        <p >{allStrings.email}</p>
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="chooseForget" onClick={this.phoneStep1}>
                        <p >{allStrings.phone}</p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  </MDBContainer>
                  }
                  {this.state.step === "emailStep1" &&
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandler1}
                            noValidate
                          >
                            <div className="grey-text">
                              <MDBInput
                                name="email"
                                hint={allStrings.email}
                                style={{direction:this.props.isRTL?'rtl':'ltr'}}
                                //icon="envelope"
                                group
                                type="email"
                                onChange={this.changeHandler1}
                                required
                                validate
                                error="wrong"
                                success="right"
                              />
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="text-center">
                              <MDBBtn
                                type="submit"
                                style={{ backgroundColor: '#233b5b ',width:'170px' }}
                                rounded
                                className="btn z-depth-1a"
                              >
                                {allStrings.sendCode}
                              </MDBBtn>
                            </div>
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  }
                  {
                    //phone step 1
                  this.state.step === "phoneStep1" &&
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandler2}
                            noValidate
                          >
                            <div className="grey-text">
                              <MDBInput
                                name="phone"
                                hint={allStrings.phone}
                                style={{direction:this.props.isRTL?'rtl':'ltr'}}
                                //icon="envelope"
                                group
                                type="phone"
                                onChange={this.changeHandler2}
                                required
                                validate
                                error="wrong"
                                success="right"
                              />
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="text-center">
                              <MDBBtn
                                type="submit"
                                style={{ backgroundColor: '#233b5b ',width:'170px' }}
                                rounded
                                className="btn z-depth-1a"
                              >
                                {allStrings.sendCode}
                              </MDBBtn>
                            </div>
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  }
                  {
                  //step 2
                  this.state.step === "step2" &&
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandlerPage2}
                            noValidate
                          >
                            <div className="grey-text">
                              <MDBInput
                                name="code"
                                hint={allStrings.code}
                                style={{direction:this.props.isRTL?'rtl':'ltr'}}
                                //icon="envelope"
                                group
                                type="text"
                                onChange={this.changeHandler3}
                                required
                                validate
                                error="wrong"
                                success="right"
                              />
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="text-center">
                              <MDBBtn
                                type="submit"
                                style={{ backgroundColor: '#233b5b ',width:'170px' }}
                                rounded
                                className="btn z-depth-1a"
                              >
                                {allStrings.enter}
                              </MDBBtn>
                            </div>
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  }
                  
                  {
                    //step 3
                  this.state.step === "step3" &&
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandlerPage3}
                            noValidate
                          >
                            <div className="grey-text">
                              <MDBInput
                                name="password"
                                hint={allStrings.newPassword}
                                style={{direction:this.props.isRTL?'rtl':'ltr'}}
                                //icon="envelope"
                                group
                                type="password"
                                onChange={this.changeHandler4}
                                required
                                validate
                                error="wrong"
                                success="right"
                              />
                              <div className="valid-feedback">Looks good!</div>
                              <MDBInput
                                name="confirmPassword"
                                hint={allStrings.newTime}
                                style={{direction:this.props.isRTL?'rtl':'ltr'}}
                                //icon="envelope"
                                group
                                type="password"
                                onChange={this.changeHandler4}
                                required
                                validate
                                error="wrong"
                                success="right"
                              />
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="text-center">
                              <MDBBtn
                                type="submit"
                                style={{ backgroundColor: '#233b5b ',width:'170px' }}
                                rounded
                                className="btn z-depth-1a"
                              >
                                {allStrings.enter}
                              </MDBBtn>
                            </div>
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  }
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  })
  
  const mapDispatchToProps = {
    setUser,
  }
  
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(ForgetPassword)
  );
  
