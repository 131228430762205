
import React from 'react';
import './splash.css';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
//import firebase from 'firebase';
import {userToken,setUser} from '../../redux/actions/AuthActions'
import  allStrings from '../../assets/strings'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {Collapsed} from '../../redux/actions/MenuAction'

class Splash extends React.Component {

    state = {
        loading: true,
    };
  

  constructor(props){
    super(props)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  /*
  getFirebaseToken = async () => {
    try {
  
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      this.checkToken(token);
      //console.log('user token: ', token);
  
      return token;
    } catch (error) {
      //console.error(error);
    }
  }*/

      checkToken =  (token) => {
        const t = localStorage.getItem('@USER-AHYA-TEAMS')
          if(t){
              this.props.userToken(t);
          }else{
            localStorage.setItem('@USER-AHYA-TEAMS-TOKEN',token)
              this.props.userToken(token)
          }
          console.log(token)
      }

      checkLogin =  () => {      
        const userJSON = localStorage.getItem('@USER-AHYA-TEAMS');
        if(userJSON){
            const userInfo = JSON.parse(userJSON);
            console.log(userInfo)
            this.props.history.replace('/Home') 

          }else{
            this.props.history.push('/Login')
          }
      }

      checkLanguage =  () => {      
      const lang = localStorage.getItem('@LANG');
      if(lang){
            allStrings.setLanguage(lang) 
            if(lang==='ar'){
            this.props.ChangeLanguage(true)  
            }else{
            this.props.ChangeLanguage(false)
            }
          
        }else{
          allStrings.setLanguage('ar') 
          this.props.ChangeLanguage(true)  
        }
      }
      checkMenu =  () => {      
        const menu = localStorage.getItem('@MENU');
        if(menu){
              if(menu==='true'){
              this.props.Collapsed(true)  
              }else{
              this.props.Collapsed(false)
              }
            
          }else{
            this.props.Collapsed(true)  
          }
        }
      


   
     componentDidMount(){
      //this.getFirebaseToken()
      this.checkLanguage()
      this.checkMenu()
      setTimeout(()=>{this.checkLogin()},500)
     }
     
    
    render() {
      return (
        <div className="splash">
        <div className="theFrame">
          <div className="TheCenter">
              <div className="dot-1"></div>
              <div className="dot-2"></div>
              <div className="dot-3"></div>
          </div>
       </div>
      </div> 
      );
    }
  }

  
  const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
  })
  
  const mapDispatchToProps = {
    userToken,
    setUser,
    ChangeLanguage,
    Collapsed
  }

  export default  withRouter(connect(mapToStateProps,mapDispatchToProps)(Splash));

