import React from 'react';
import './search.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn } from "mdbreact";
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter,Redirect} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {Input,Checkbox} from 'antd';
import LoadCard from '../../component/load card/load card'
import { SearchOutlined  } from '@ant-design/icons';

class Search extends React.Component {
  
  state = {
    users:[],
    deals:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    showdeals:true,
    showusers:true,
    value:this.props.history.location.state?this.props.history.location.state.data.search:'',
    checkedValues:["deals","users",]
  }
  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    this.getuserWithoutPagenation()
    this.getdealsWithoutPagenation()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  getdealsWithoutPagenation = () => {
    this.setState({loading:false})
    let value = this.state.value
    console.log("value",value)
    let url =``;
    if(value !== ''){
        url = `${BASE_END_POINT}deals?search=${value}`
    }else{
      url = `${BASE_END_POINT}deals`
    }
    
    axios.get(url,{headers:{
      'Authorization':`Bearer ${this.props.currentUser.token}`,
      'Accept-Language':this.props.isRTL?'ar':'en'
    }})
    .then(response=>{
      console.log("deals",response.data.data)
      this.setState({deals:response.data.data})
      this.setState({loading:false})
    })
    .catch(error=>{
      console.log("ALL deals ERROR")
      console.log(error.response)
    })
  }
  getuserWithoutPagenation = () => {
    this.setState({loading:false})
    let value = this.state.value
    let url =``;
    if(value !== ''){
        url = `${BASE_END_POINT}withoutPagenation/get?search=${value}`
    }else{
      url = `${BASE_END_POINT}withoutPagenation/get`
    }
    axios.get(url,{headers:{
      'Authorization':`Bearer ${this.props.currentUser.token}`,
      'Accept-Language':this.props.isRTL?'ar':'en'
    }})
    .then(response=>{
      console.log("users",response.data)
      this.setState({users:response.data.data})
    })
    .catch(error=>{
      console.log("ALL users ERROR")
      console.log(error.response)
    })
  }
  componentDidMount(){
  }
  onChange = (checkedValues) => {
    console.log('checked = ', checkedValues);
    let deals = checkedValues.indexOf("deals");
    let users = checkedValues.indexOf("users");
    if(deals !== -1){
      this.setState({showdeals:true})
    }else{
      this.setState({showdeals:false})
    }
    if(users !== -1){
      this.setState({showusers:true})
    }else{
      this.setState({showusers:false})
    }
  }
 
  render(){
    console.log("value",this.state.value)

    if (this.props.currentUser.user.type !== "ADMIN") return <Redirect to='/Home' /> 
  return (
    <div className="Category search">
      <div className="filters" style={{marginBottom: "2rem"}}>
        <MDBContainer> 
          <MDBRow>
            <MDBCol xl="4" lg="4" md="4" sm="12">
              <MDBBtn  
              onClick={() => {
                this.getuserWithoutPagenation()
                this.getdealsWithoutPagenation()
                document.getElementById('theSearch').value = "";
              }} 
                style={{width:'150px',padding:'9px',height:'40px',float:'right',margin:0 }}
                    className="btn z-depth-1a "
                    color="primary"
                >
                  {allStrings.Search}
              </MDBBtn>
            </MDBCol>
            <MDBCol xl="8" lg="8" md="8" sm="12">
              <Input 
              id="theSearch"
                onKeyUp={(e)=>{
                      console.log("ENTER   ",e.target.value)
                      this.setState({value:e.target.value})
                }} 
                placeholder={allStrings.Search}
                size="large"  prefix={<SearchOutlined />} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Checkbox.Group defaultValue={this.state.checkedValues}
         style={{ width: '100%',textAlign: 'end',padding: '10px 30px' }}  onChange={this.onChange}>
          <Checkbox value="deals">{allStrings.Deals}</Checkbox>
          <Checkbox value="users">{allStrings.users}</Checkbox>
        </Checkbox.Group>
      </div>
      <MDBContainer> 
        {this.state.showdeals === true &&
        <MDBRow>
          <MDBCol md="12">
            <label  htmlhtmlFor="defaultFormRegisterNameEx2"
            style={{
              float:'right',
              color: 'slategray',
              fontSize: '18px',
              marginBottom: '20px',
              marginTop: '20px'
            }}>
            {allStrings.Deals}
            </label>
          </MDBCol>
        </MDBRow>
        }
        {this.state.showdeals === true &&
        <div className="searchCountainer">
          <MDBRow>
            {this.state.loading?
              <LoadCard/>
              :
              this.state.deals.length > 0 ?
                this.state.deals.map(deal=>(
                  <MDBCol xl="4" lg="6" md="12" sm="12">
                  <div className="categoryCard">
                  <MDBContainer> 
                    <MDBRow>
                      <MDBCol xl="2" lg="2" md="2" sm="2">
                      </MDBCol>
                      <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                        <div className="categoryInfo" onClick={() => this.props.history.push('/dealInfo',{data:deal})}>
                          <p style={{
                            fontWeight: '600',
                            color:'#233b5b',
                            marginBottom: '6px',
                            fontSize: '1.2rem'
      
                          }}>{deal.clientName?".. " +deal.clientName.substr(0,10):"" }</p>
                          <p style={{
                          color: '#A4A3B0',
                          fontSize: '14px',
                          marginBottom: '8px'}}>{deal.salesName?".. " +deal.salesName.substr(0,10):"" }</p>
                         
                        </div>
                      </MDBCol>
                      <MDBCol xl="4" lg="4" md="4" sm="4">
                        <div className="categoryImg" >
                          {/*<img crossorigin="anonymous"alt="" style={{width: '75px',
                          height: '75px',marginBottom:'10px',
                          borderRadius: '100%'}} src={deal.img?deal.img:require('../../assets/images/profileicon.jpg')}></img>
                          */}</div>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer> 
                  </div>
                  </MDBCol>
                ))
                :
                <div className="noData">{allStrings.noData}</div>
            }
            </MDBRow>
        </div>
      }{this.state.showusers === true &&
        <MDBRow>
          <MDBCol md="12">
            <label  htmlhtmlFor="defaultFormRegisterNameEx2"
            style={{
              float:'right',
              color: 'slategray',
              fontSize: '18px',
              marginBottom: '20px',
              marginTop: '20px'
            }}>
            {allStrings.users}
            </label>
          </MDBCol>
        </MDBRow>
       }{this.state.showusers === true &&
        <div className="searchCountainer">
          <MDBRow>
          {this.state.loading?
            <LoadCard/>
            :
            this.state.users.length > 0 ?
              this.state.users.map(user=>(
                <MDBCol xl="4" lg="6" md="12" sm="12">
                <div className="categoryCard" >
                <MDBContainer> 
                  <MDBRow>
                    <MDBCol xl="2" lg="2" md="2" sm="2">
                    </MDBCol>
                    <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                      <div className="categoryInfo" onClick={() => this.props.history.push('/userInfo',{data:user})} >
                        <p style={{
                          fontWeight: '600',
                          color:'#233b5b',
                          marginBottom: '6px',
                          fontSize: '1.2rem'
    
                        }}>{user.fullname?".. " +user.fullname.substr(0,10):allStrings.notExist}</p>
                      
                        <p style={{
                        color: '#233b5b',
                        fontSize: '16px',
                        marginBottom: '8px',
                        display:'inline-block'}}>{user.phone} </p>
                      </div>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="4">
                      <div className="categoryImg" >
                        <img crossorigin="anonymous"alt="" style={{width: '75px',
                        height: '75px',marginBottom:'10px',
                        borderRadius: '100%'}} src={user.img?user.img:require('../../assets/images/profileicon.jpg')}></img>
                      </div>
                    </MDBCol>
                  </MDBRow>
                
                </MDBContainer> 
                </div>
                </MDBCol>
              ))
              :
              <div className="noData">{allStrings.noData}</div>
          }
          </MDBRow>
        </div>
        }
      </MDBContainer> 
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
}

export default  withRouter( connect(mapToStateProps,mapDispatchToProps) (Search))
