import React from "react";
import "./add user.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import "react-rater/lib/react-rater.css";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter ,Redirect} from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { message } from "antd";
import { TreeSelect ,Spin} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class AddUser extends React.Component {
  
  state = {
    permission:null,
    fullname: '',
    email:'',
    phone:'',
    password:'',
    type:'ISP',
    img: null,
    imgType:'data',
    fileType:"data",
    filePreviewName:[],
    selectedFile: null,
    imagePreviewUrl:[],
    imgName:'',
    loading:false,
    country:null,
    countries:[],
    permissions:[],
    
  };
 
  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  componentDidMount(){
    this.getCountries()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getPermissions = () => {
    axios.get(`${BASE_END_POINT}permissions/withoutPagenation/get`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`
      },
     })
    .then(response=>{
      console.log(response.data)
      this.setState({permissions:response.data.data,loading:false})
    })
    .catch(error=>{
      console.log("ALL permissions ERROR")
      console.log(error.response)
    })
  }
  getCountries = () => {
    axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`
        },
       })
    .then(response=>{
      console.log("countries",response.data)
      this.setState({countries:response.data.data})
    })
    .catch(error=>{
      console.log("ALL countries ERROR")
      console.log(error.response)
    })
  }
  add = () => {
    const {img,fullname,phone,email,password,country,type,permission} = this.state
    if(hasValue(email)
      &&hasValue(fullname)&&hasValue(password)&&hasValue(phone)){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('email',email)
        data.append('phone',phone)
        data.append('fullname',fullname)
        data.append('password',password)
        data.append('type',type)
        data.append('country',country)
        if(type ==="SUB-ADMIN"){
          data.append('permission',permission)
        }
        if(img != null){
          data.append('img',img)
        }
        
        console.log(Array.from(data))
        axios.post(`${BASE_END_POINT}addUser`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Accept-Language': this.props.isRTL?'ar':'en',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/users')
        })
        .catch(error=>{
            console.log(error.response.data)
            if(error.response.data.errors){
              l.then(() => message.error(error.response.data.errors.msg[0].msg, 2.5))
            }else{
              l.then(() => message.error(allStrings.error, 2.5))
            }
            
        })
    }
  }
  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.add()
  };

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  fileChangedHandler = event => {
    console.log('000')
    console.log(event.target.files[0])
    this.setState({
      img: event.target.files[0],
      imgName: event.target.files[0].name,
      imgType:"data"
    })
    console.log(this.state.img)
    
  }
  removeFile = () => {
    this.setState({
        img:null,
        imgName:'',
        imgType:"url"
    });
  }

  render() {
    if (this.props.currentUser.user.type !== "ADMIN") return <Redirect to='/Home' /> 

    return (
      <div className="UserInfo">
        {/*<SideMenu />*/}
        <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#233b5b'}}>

        <MDBContainer>
          <MDBRow>
            <MDBCol xl="12" lg="12" md="12" sm="12">
              <div className="infoCard">
                <Card className="editCard" style={{ overflow: "visible", outline: "none",marginBottom: "0px"}} >
                  <CardMedia style={{ height: "0px" }} />
                  <CardHeader></CardHeader>
                  
                  <div  style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                      <div className="topIcon">
                          <span className="material-icons" style={{color:'white',fontSize:23}}>group</span>
                      </div>
                      <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                      <p>{allStrings.AddUser}</p>
                      </div>
                  </div>
                  
                            
                  <CardContent style={{ minHeight: "390px", outline: "none" }}>
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                        <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    <div className="grey-text">
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.name}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.fullname} type="text" id="form645" name="fullname" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.email}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?&#^-_. +]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.email} type="email" id="form65" name="email" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                          <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                                          <TreeSelect
                                          showSearch={true} 
                                          style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                          treeCheckable = {false}    
                                          disabled={this.state.actionType==="VIEW"?true:false}     
                                          treeNodeFilterProp="title"                      
                                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                          placeholder={allStrings.country}
                                          showCheckedStrategy= {SHOW_PARENT}
                                          onChange={(value) => {
                                              this.setState({country:value});
                                          }} 
                                          
                                          >
                                              {this.state.countries.map(val=>(
                                                  <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                              ))}
                                          </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form615" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                            <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.phone} type="text" id="form615" name="phone" className="form-control" onChange={this.changeHandler} required  validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.userType}</label>
                                            <TreeSelect virtual={false}
                                                showSearch={false} 
                                                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                treeCheckable = {false}         
                                                treeNodeFilterProp="title"                      
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder={allStrings.type}
                                                onChange={(value) => {
                                                    console.log(value)
                                                    this.setState({type:value});
                                                    if(value ==="SUB-ADMIN"){
                                                      this.getPermissions()
                                                    }
                                                }} 
                                            
                                            >
                                                <TreeNode value="ADMIN" title={allStrings.admin} key="ADMIN" />
                                                <TreeNode value="SUB-ADMIN" title={allStrings.subAdmin} key="SUB-ADMIN" />
                                                <TreeNode value="ISP" title={allStrings.isp} key="ISP" />                                                
                                                
                                            </TreeSelect>
                                        </MDBCol>
                                        
                                        <MDBCol md="6">
                                            <div className="md-form">
                                            <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.password}</label>
                                            <input  style={{direction:this.props.isRTL?'rtl':'ltr'}} type="text" id="form605" name="password" className="form-control" onChange={this.changeHandler} required validate/>
                                            <div class="valid-feedback">{allStrings.correct}</div>
                                            <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    {this.state.type ==="SUB-ADMIN"&&
                                      <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                        <MDBCol md="6">
                                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.permission}</label>
                                        <TreeSelect virtual={false}
                                            value={this.state.permission?this.state.permission:[]}
                                            showSearch={true} 
                                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                                            treeCheckable = {false}         
                                            treeNodeFilterProp="title"                      
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder={allStrings.permission}
                                            onChange={(value) => {
                                            console.log(value)
                                            this.setState({permission:value});
                                            }} 
                                        
                                        >
                                            {this.state.permissions.map(val=>(
                                                <TreeNode value={val.id} title={this.props.isRTL?val.permission_ar:val.permission_en} key={val.id} />
                                            ))}
                                        </TreeSelect>
                                        </MDBCol>
                                        <MDBCol md="6">
                                        </MDBCol>
                                      </MDBRow>
                                    }
                                    <MDBRow>
                                        <MDBCol md="12">
                                        <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.profileImg}</label>

                                            <div className="fileUpload2"> 
                                                <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                            </div>
                                            <div className="preview">
                                            {this.state.img&&
                                            <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <img crossorigin="anonymous"alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img} className="previewImg2"/>
                                                
                                                <span className="imgName">{this.state.imgName}</span>
                                                <MDBIcon icon="trash" className="mr-2 removeImg3"
                                                onClick={() => this.removeFile()}  ></MDBIcon>
                                            </div>
                                            }
                                            </div>
                                            
                                        </MDBCol>
                                    </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                      <MDBBtn
                                      type="submit"
                                      rounded
                                      className="btn-block z-depth-1a"
                                      >
                                          <MDBIcon icon="plus" className="mr-2" />
                                          {allStrings.add}        
                                      </MDBBtn>
                                      
                                  </div>
                                </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </CardContent>
                </Card>
              </div>
            </MDBCol>
          </MDBRow>
         
        </MDBContainer>
        </Spin>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchusernamerops = {
    setUser,
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchusernamerops)(AddUser)
  );