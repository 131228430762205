import React from 'react';
import './deal info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message} from "antd";
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';

class dealInfo extends React.Component {
    state = {
      actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
      page:1,
      pages:1,
        id:this.props.location.state.data.id,
        unitCode:this.props.location.state.data.unitCode,
        value:this.props.location.state.data.value,
        status:this.props.location.state.data.status,
        clientName:this.props.location.state.data.clientName?this.props.location.state.data.clientName:'',
        clientPhone:this.props.location.state.data.clientPhone?this.props.location.state.data.clientPhone:'',
        clientCountry:this.props.location.state.data.clientCountry?this.props.location.state.data.clientCountry.id:null,
        developerName:this.props.location.state.data.developerName?this.props.location.state.data.developerName:null,
        salesName:this.props.location.state.data.salesName?this.props.location.state.data.salesName:'',
        salesPhone:this.props.location.state.data.salesPhone?this.props.location.state.data.salesPhone:'',
        salesCountry:this.props.location.state.data.salesCountry?this.props.location.state.data.salesCountry.id:null,

        createdAt:this.props.location.state.data.createdAt,
        closedDate:this.props.location.state.data.closedDate,
        client:this.props.location.state.data.client?this.props.location.state.data.client:null,
        user:this.props.location.state.data.user,
        refusedReason:this.props.location.state.data.reason?this.props.location.state.data.reason:"",
        paymentPlan:this.props.location.state.data.paymentPlan?this.props.location.state.data.paymentPlan:null,
        locations:[],
        categories:[],
        reason:'',
        modal:false,
        modal2:false,
        dealCommission:0,
        dspCommission:0,
        provement:this.props.location.state.data.provement?this.props.location.state.data.provement:null,
        provementType:"url",
        provementName:""
    };

    constructor(props){
      super(props)
      window.scrollTo(0,0)
      if(this.props.isRTL){
          allStrings.setLanguage('ar')
      }else{
          allStrings.setLanguage('en')
      } 
    }

    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    
    accept = () => {
      let l = message.loading('Wait..', 2.5)
      let data = {
        "dealCommission":this.state.dealCommission
      }      
      axios.put(`${BASE_END_POINT}deals/${this.props.location.state.data.id}/accept`,data,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
      })
      .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 

      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    refused = () => {
      if(this.state.reason !==''){
        let l = message.loading('Wait..', 2.5)
      
        axios.put(`${BASE_END_POINT}deals/${this.props.location.state.data.id}/refuse`,{reason:this.state.reason},{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
        })
        .then(response=>{
          l.then(() => message.success(allStrings.done, 2.5) )
          this.toggle()
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
      }
      
    }
    update = () => {
        const {salesPhone,salesName,clientPhone,clientName,clientCountry,
        value,developerName,provement,provementType,salesCountry,unitCode} = this.state
        
        
        if(hasValue(salesPhone)&&hasValue(clientName)&&hasValue(clientPhone)
        &&hasValue(salesName)){
          let l = message.loading('Wait..', 2.5)
            var data = new FormData()
            data.append('salesName',salesName)
            data.append('salesPhone',salesPhone)
            data.append('salesCountry',salesCountry)
            data.append('clientName',clientName)
            data.append('clientPhone',clientPhone)
            data.append('clientCountry',clientCountry)
            data.append('developerName',developerName)
            data.append('value',value)
            data.append('unitCode',unitCode)
            if(provement != null && provementType ==="data"){
              data.append(`provement`,provement)
            }
            axios.put(`${BASE_END_POINT}deals/${this.props.location.state.data.id}`,data,{
              headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization':`Bearer ${this.props.currentUser.token}`,
              'Accept-Language':this.props.isRTL?'ar':'en'
              },
            }).then(response=>{
                console.log("deal UPDATED  ",response.data)
                l.then(() => message.success(allStrings.done, 2.5) )
                this.props.history.goBack() 
            }).catch(error=>{
                console.log(error.response)
                if(error.response.data.errors.msg[0].msg){
                    l.then(() => message.error(error.response.data.errors.msg[0].msg, 2.5))

                }else{
                    l.then(() => message.error(allStrings.error, 2.5))

                }
            })
        }
    }

    delete = () => {
        let l = message.loading('Wait..', 2.5)
        axios.delete(`${BASE_END_POINT}deals/${this.props.location.state.data.id}`,{
         headers: {
           'Content-Type': 'application/json',
           'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
         },
        })
        .then(response=>{
          console.log("deal DELETED")
          l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.goBack() 
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
   
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.update()
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    submitRefusedHandler = event => {
      event.preventDefault();
      console.log(event.target.className)
      event.target.className += " was-validated";
      this.refused()
    };
    modal = () =>(
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitRefusedHandler}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="12">
                          <div class="md-form">
                            <label for="form920" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.reason}</label>
                            <br></br>
                            <textarea style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 0'}} type="text" id="form920" class="form-control" onChange={this.changeHandler} required validate  name="reason"/>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn disabled={this.state.reason !==""?false:true} type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon icon="times" className="mr-2" />
                        {allStrings.done}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    // modal
    toggle = () => {
    this.setState({
        modal: !this.state.modal,
    });
    }
    changeHandlerAccept = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
    submitHandlerAccept = event => {
      event.preventDefault();
      console.log(event.target.className)
      event.target.className += " was-validated";
      this.accept()
    };
    modal2 = () =>(
      <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="lg">
        <MDBModalHeader toggle={this.toggle2} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerAccept}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="12">
                          <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.dealCommission}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={0} id="form92" className="form-control" onChange={this.changeHandlerAccept} required validate  name="dealCommission" defaultValue={this.state.dealCommission}/>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                        {allStrings.done}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    toggle2 = () => {
      this.setState({
          modal2: !this.state.modal2,
      });
    }
    fileChangedHandlerProvement = event => {
      this.setState({
        provement: event.target.files[0],
        provementType:"data",
        provementName:event.target.files[0].name
      })
      
    }
    removeFileProvement = () => {
      this.setState({
          provement:null,
          provementType:"url",
          provementName:""
      });
    }
      
    render(){
      if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('DEALS')) return <Redirect to='/Home' />
      return (
        <div className="App">
            <MDBContainer> 
              {this.modal()}
              {this.modal2()}
                <MDBRow>  
                    <MDBCol md="12">
                        <div className="infoCard">
                            <Card className="editCard">
                            <CardMedia
                            style={{height:"0px"}}
                            />
                            <CardHeader></CardHeader>
                            <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                <div className="topIcon">
                                    <span className="material-icons" style={{color:'white',fontSize:23}}>content_paste</span>
                                </div>
                                <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                                <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                                </div>
                            </div>
                            <CardContent style={{minHeight:"390px",outline:'none'}}>
                            <MDBContainer>
                                
                                <MDBRow>
                                    <MDBCol md="12">
                                    <form
                                    className="needs-validation"
                                    onSubmit={this.submitHandler}
                                    noValidate
                                    >
                                        
                                        <div className="grey-text">
                                      {this.state.actionType==="VIEW"&&
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form062" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                                                <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.status==="PENDING"?allStrings.pending:this.state.status==="ACCEPTED"?allStrings.accepted:allStrings.refused} type="text"  id="form062" name="status" className="form-control" disabled validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                                <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form605"  className="form-control" />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        }
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.clientName}</label>
                                                <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.clientName} type="text" id="form56" name="clientName" className="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.clientPhone}</label>
                                                <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.clientPhone} type="text" id="form645" name="clientPhone" className="form-control" onChange={this.changeHandler} required  validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form546" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.developerName}</label>
                                                <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.developerName} type="text" id="form546" name="developerName" className="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form546" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.unitCode}</label>
                                                <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.unitCode} type="text" id="form546" name="unitCode" className="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form516" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.salesName}</label>
                                                <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.salesName} type="text" id="form516" name="salesName" className="form-control" onChange={this.changeHandler} required validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form625" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.salesPhone}</label>
                                                <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.salesPhone} type="text" id="form625" name="salesPhone" className="form-control" onChange={this.changeHandler} required  validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow style={{flexDirection: this.props.isRTL?'row-reverse':'row'}}>
                                          <MDBCol md="12">
                                              <div className="md-form">
                                              <label htmlFor="form59" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.value}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={0} value={this.state.value}  id="form59" name="value" className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                        </MDBRow>
                                        {this.state.status ==="REFUSED"&&
                                        <MDBRow>
                                          <MDBCol md="12">
                                            <div class="md-form">
                                              <label for="form9220" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.reason}</label>
                                              <br></br>
                                              <textarea disabled value={this.state.refusedReason} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 0'}} type="text" id="form9220" class="form-control"  validate  name="reason"/>
                                            </div>
                                          </MDBCol>
                                        </MDBRow>
                                        }{this.state.actionType ==="VIEW"&&
                                        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                            <MDBCol md="10" xs="10">
                                                <div class="md-form">
                                                <label for="form80" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.isp}</label>
                                                <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user?this.state.user.fullname:''} type="text" id="form80" class="form-control"  />
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="2" xs="2">
                                          
                                            <p style={{textAlign: 'center',marginTop: '2rem',color: '#2BBBAD'}}>
                                                <span style={{cursor:'pointer',position: 'absolute',padding: '3px'}} class="material-icons"
                                                onClick={()=>{this.props.history.push('userInfo',{data:this.state.user,actionType:'VIEW'}) }}>
                                                  remove_red_eye
                                                </span>
                                                <span style={{cursor:'pointer',fontSize: '16px',fontWeight: '500',marginLeft: '2rem'}}
                                                  onClick={()=>{this.props.history.push('userInfo',{data:this.state.user,actionType:'VIEW'}) }}>
                                                  {allStrings.show}
                                                </span> 
                                              </p>
                                            </MDBCol>
                                        </MDBRow>
                                        }
                                        {this.state.actionType==="VIEW"&& this.state.createdAt&&
                                        <MDBRow>
                                          <MDBCol md="12">
                                              <div className="md-form">
                                              <label htmlFor="form062" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                                              <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={moment(this.state.createdAt).format('lll')} type="text"  id="form062"  className="form-control"  validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              </div>
                                          </MDBCol>
                                          
                                        </MDBRow>
                                        }
                                        {this.state.actionType==="VIEW"&& this.state.closedDate&&
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <div className="md-form">
                                                <label htmlFor="form062" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.closedDate}</label>
                                                <input disabled  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={moment(this.state.closedDate).format('lll')} type="text"  id="form062"  className="form-control"  validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            
                                        </MDBRow>
                                        }
                                        {this.state.actionType ==="EDIT"&&
                                            <MDBCol md="12">
                                            <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.provement}</label>
                                                <div className="fileUpload2"> 
                                                    <input  key={this.state.provement} type='file' className="upImgs2" onChange={this.fileChangedHandlerProvement} ></input>
                                                    <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.uploadFile}</span></span>
                                                </div>
                                                {this.state.provement != null && this.state.provementType !=="url" &&
                                                <div className="preview">
                                                    <div style={{ display: "inline-block" }}>
                                                    <p className="previewPdf">{this.state.provementName}
                                                    <MDBIcon icon="times" className="mr-2 removePdf"
                                                        onClick={() => this.removeFileProvement()}  ></MDBIcon>
                                                    </p>
                                                    </div>
                                                </div>
                                                }
                                            </MDBCol>
                                            }
                                        {this.state.actionType ==="VIEW"&&this.state.provement != null &&
                                            <div>
                                                <label htmlFor="form062" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.provement}</label>
                                                <div>
                                                  <a href={this.state.provement} target="blank" style={{background:'#233b5b',
                                                  color: '#fff',
                                                  padding: '3px 40px',
                                                  marginLeft: '15px'}}
                                                  >
                                                      {allStrings.openFile}          
                                                  </a>
                                                </div>
                                            </div>

                                        }
                                        {this.state.actionType==="VIEW"&&this.state.paymentPlan != null &&
                                            <div>
                                                <label htmlFor="form062" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.paymentPlan}</label>
                                                <div>
                                                  <a href={this.state.paymentPlan} target="blank" style={{background:'#233b5b',
                                                  color: '#fff',
                                                  padding: '3px 40px',
                                                  marginLeft: '15px'}}
                                                  >
                                                      {allStrings.openFile}          
                                                  </a>
                                                </div>
                                            </div>
                                        }
                                        
                                        

                                        
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="text-center">
                                          {this.state.actionType==="EDIT"&&
                                            <MDBBtn
                                            style={{background:'#233b5b',color:'#fff'}}
                                            rounded
                                            color="#233b5b"
                                            type="submit"
                                            className="btn-block z-depth-1a"
                                            >
                                                <MDBIcon icon="edit" className="mr-2" />
                                                {allStrings.update}          
                                            </MDBBtn>
                                          }{this.state.actionType==="VIEW"&& this.props.currentUser.user.type === "ADMIN" &&
                                            <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                                                <MDBIcon icon="trash-alt" className="mr-2" />
                                                {allStrings.remove}
                                            </MDBBtn>
                                          }{this.state.actionType==="VIEW"&&this.state.status ==="PENDING"&& 
                                            <MDBBtn onClick={()=>{this.toggle2()}} rounded color="green" className="btn-block z-depth-1a">
                                                <MDBIcon  className="mr-2" />
                                                {allStrings.accept}
                                            </MDBBtn>
                                          }
                                          {this.state.actionType==="VIEW"&&this.state.status ==="PENDING"&&
                                            <MDBBtn onClick={()=>{this.toggle()}} rounded color="red" className="btn-block z-depth-1a">
                                                <MDBIcon  className="mr-2" />
                                                {allStrings.refuse}
                                            </MDBBtn>
                                          }
                                            
                                        </div>
                                    </form>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                            </CardContent>
                        </Card>
                    </div>
                </MDBCol>
                </MDBRow>
          </MDBContainer>
        </div> 
      )
    }
}


const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
});
  
const mapDispatchToProps = {
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(dealInfo)
);