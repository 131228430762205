import React from 'react';
import './user info.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Image,Spin ,TreeSelect,DatePicker} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip';
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;
class UserInfo extends React.Component {
    state = {
        actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
        id:this.props.location.state.data.id,
        user: this.props.location.state?this.props.location.state.data:[],
        fullname: this.props.location.state.data.fullname?this.props.location.state.data.fullname:"",
        email:this.props.location.state.data.email?this.props.location.state.data.email:"",
        type:this.props.location.state.data.type?this.props.location.state.data.type:"",
        phone:this.props.location.state.data.phone?this.props.location.state.data.phone.replace("+2", ""):"",
        earns:this.props.location.state.data.earns?this.props.location.state.data.earns:0,
        totalEarns:this.props.location.state.data.totalEarns?this.props.location.state.data.totalEarns:0,
        deals:this.props.location.state.data.deals?this.props.location.state.data.deals:0,
        approved:this.props.location.state.data.approved?this.props.location.state.data.approved:false,
        called:this.props.location.state.data.called?this.props.location.state.data.called:false,
        block:this.props.location.state.data.block?this.props.location.state.data.block:false,
        country:this.props.location.state.data.country?this.props.location.state.data.country.id:null,
        yearsExperience:this.props.location.state.data.yearsExperience?this.props.location.state.data.yearsExperience:"",
        birthday:this.props.location.state.data.birthday?this.props.location.state.data.birthday:null,
        createdAt:"",
        modal:false,
        blockReason:"",
        reason:"",

        img:this.props.location.state?this.props.location.state.data.img !== ""?this.props.location.state.data.img:null:null,
        fileType:"data",
        filePreviewName:[],
        selectedFile: null,
        imagePreviewUrl:[],
        loading:true,
        refresh:false,
        page:1,
        pages:1,
        imgType:'url',
        imgName:'',
        modal2:false,
        cost:0,
        modal3:false,
        countries:[],
        permissions:[],
        permission:this.props.location.state.data.permission?this.props.location.state.data.permission.id:1,
    };
      
    constructor(props){
      super(props)
      window.scrollTo(0,0)
      console.log(this.props.location.state)
      this.getUserData()
      this.getCountries()
      this.getPermissions()
      this.fileChangedHandler = this.fileChangedHandler.bind(this);
      if(this.props.isRTL){
        allStrings.setLanguage('ar')
      }else{
        allStrings.setLanguage('en')
      } 
    }
    getPermissions = () => {
      axios.get(`${BASE_END_POINT}permissions/withoutPagenation/get`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`
        },
       })
      .then(response=>{
        console.log(response.data)
        this.setState({permissions:response.data.data,loading:false})
      })
      .catch(error=>{
        console.log("ALL permissions ERROR")
        console.log(error.response)
      })
    }
    getCountries = () => {
      axios.get(`${BASE_END_POINT}countries/withoutPagenation/get`,{
          headers: {
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${this.props.currentUser.token}`
          },
         })
      .then(response=>{
        console.log("countries",response.data)
        this.setState({countries:response.data.data})
      })
      .catch(error=>{
        console.log("ALL countries ERROR")
        console.log(error.response)
      })
    }
    getUserData = () => {
      axios.get(`${BASE_END_POINT}${this.state.id}/getUser`,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        }})
      .then(response=>{
          console.log('theUser  ',response.data.data)
          this.setState({
            loading:false,
            yearsExperience:response.data.data.yearsExperience?response.data.data.yearsExperience:"",
            birthday:response.data.data.birthday?response.data.data.birthday:null,
            permission:response.data.data.permission?response.data.data.permission.id:[],
            user:response.data.data?response.data.data:[],
            phone:response.data.data.phone?response.data.data.phone.replace("+2", ""):"",
            country:response.data.data.country?response.data.data.country.id:null,
            email:response.data.data.email?response.data.data.email:"",
            fullname:response.data.data.fullname?response.data.data.fullname:"",
            type:response.data.data.type?response.data.data.type:"",
            img:response.data.data.img?response.data.data.img:null,
            earns:response.data.data.earns?response.data.data.earns:0,
            deals:response.data.data.deals?response.data.data.deals:0,
            totalEarns:response.data.data.totalEarns?response.data.data.totalEarns:0,
            approved:response.data.data.approved?response.data.data.approved:false,
            called:response.data.data.approved?response.data.data.called:false,
            block:response.data.data.block?response.data.data.block:false,
            createdAt:response.data.data.createdAt?response.data.data.createdAt:false,
           })
      })
      .catch(error=>{
          console.log('theUser ERROR  ',error)
          console.log('theUser ERROR  ',error.respose)
      })
    }
    
    componentDidMount(){
      if(this.props.isRTL){
        allStrings.setLanguage('ar')
      }else{
        allStrings.setLanguage('en')
      }
    }
    update = () => {
        const {img,fullname,phone,email,password,country,permission,type,birthday} = this.state
        let l = message.loading('Wait..', 2.5)
      
        if(hasValue(fullname)&&hasValue(email)&&hasValue(phone) ){
            
            var data = new FormData()
            data.append('country',country)
            data.append('fullname',fullname)
            data.append('type',type)
            data.append('email',email)
            if(password !== '' && password !== undefined){
              data.append('password',password)
            }
            if(type ==="SUB-ADMIN"){
              data.append('permission',permission)
            }
            if(birthday){
              data.append('birthday',birthday)
            }
            
            data.append('phone', phone)
            
            if(img != null){
                data.append('img',img)
            }
            console.log(Array.from(data))
            
            axios.put(`${BASE_END_POINT}user/${this.state.id}/updateInfo`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`,
            'Accept-Language':this.props.isRTL?'ar':'en'
            },
            })
            .then(response=>{
            l.then(() => message.success(allStrings.done, 2.5) )
              this.props.history.goBack()
            })
            .catch(error=>{
                console.log(error.response)
                if(error.response.data.errors){
                  l.then(() => message.error(error.response.data.errors.msg[0].msg, 2.5))
                }else{
                  l.then(() => message.error(allStrings.error, 2.5))
                }
            })
        }
    }
    submitHandler = event => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.update()
    };
  
    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    
    fileChangedHandler = event => {
      this.setState({
        img: event.target.files[0],
        imgName: event.target.files[0].name,
        imgType:"data"
      })
      
    }
    removeFile = () => {
      this.setState({
          img:null,
          imgName:'',
          imgType:"url"
      });
    }
    
    imageSection = () =>{
        
        return(
          <MDBCol  xl ="4" lg = "5"  md="12" sm="12">
          <div className="infoCard">
              <div style={{width:"100%" ,padding:'10px'}}>
              <Image
                width="100%"
                placeholder={true}
                style={{borderRadius:'6px',width:"100%",height:'280px'}} 
                src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.props.location.state.data.img}
              />
              
              </div>
              <MDBBtn
                color="red"
                rounded
                style={{ borderRadius: "2px",background:'red' }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                    this.delete()
                }}
              >
                <MDBIcon icon="trash-alt" className="mr-2" />
                {allStrings.remove}
              </MDBBtn>
          </div>
          
      </MDBCol>
        )
    }
    buttonsSection = () =>{
      return(
        <div className="btnOption">
          <MDBRow>
            <MDBCol md="">
              <MDBBtn
                rounded
                color="warning"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={this.toggle} 
              >
                <MDBIcon icon="check-circle" className="mr-2" />
                {allStrings.block}
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="success"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.block(false)
                }}
              >
                <MDBIcon icon="ban" className="mr-2" />
                {allStrings.unBlock}
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="info"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.approved(true)
                }}
              >
                <MDBIcon  className="mr-2" />
                {allStrings.approved}
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6">
              <MDBBtn
                rounded
                color="warning"
                style={{ borderRadius: "2px" }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                  this.approved(false)
                }}
              >
                <MDBIcon className="mr-2" />
                {allStrings.disApproved}
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6">
              <MDBBtn
                rounded
                style={{ borderRadius: "2px",background:'#233b5b' }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                    this.called()
                }}
              >
                {allStrings.called}
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6">
              <MDBBtn
                rounded
                style={{ borderRadius: "2px",background:'#233b5b' }}
                className="btn-block z-depth-1a"
                onClick={()=>{
                    this.toggle2()
                }}
              >
                {allStrings.payCommission}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          
        </div>
      )
    }
    delete = () => {
      let l = message.loading('Wait..', 2.5)
      axios.delete(`${BASE_END_POINT}${this.state.id}/delete`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      },
      })
      .then(response=>{
        console.log("Row DELETED")
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack()
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    approved = (approved) => {
      let uri ='';
      if(approved){
       uri = `${BASE_END_POINT}${this.state.id}/approved`
      }else{
       uri = `${BASE_END_POINT}${this.state.id}/dis-approved`
      }
     let l = message
      .loading('Wait..', 2.5)
      axios.put(uri,{},{
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${this.props.currentUser.token}`,
         'Accept-Language':this.props.isRTL?'ar':'en'
       },
     })
      .then(response=>{
           if(approved){
               l.then(() => message.success(allStrings.done, 2.5))
               this.setState({approved:true})
           }else{
              l.then(() => message.success(allStrings.done, 2.5))
              this.setState({approved:false})
           }
           //this.props.history.goBack()
      })
      .catch(error=>{
       console.log('Error   ',error.response)
       l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    block = (block) => {
      let uri ='';
      let data = {}
      if(block){
       uri = `${BASE_END_POINT}${this.state.id}/block`
       data={blockReason:this.state.reason}
      }else{
       uri = `${BASE_END_POINT}${this.state.id}/unblock`
      }
     let l = message
      .loading('Wait..', 2.5)
      axios.put(uri,data,{
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${this.props.currentUser.token}`,
         'Accept-Language':this.props.isRTL?'ar':'en'
       },
     })
      .then(response=>{
           if(block){
               l.then(() => message.success(allStrings.done, 2.5))
               this.setState({accountType:"BLOCKED",blockReason:this.state.reason})
               this.toggle()
           }else{
              l.then(() => message.success(allStrings.done, 2.5))
              this.setState({accountType:"ACTIVE"})
           }
           //this.props.history.goBack()
      })
      .catch(error=>{
       console.log('Error   ',error.response)
       l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    submitHandlerBlock = (event) => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.block(true)
    };
    changeHandlerBlock = (event) => {
        console.log(event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
    toggle = () => {
      console.log(this.state.modal)
      this.setState({
        modal: !this.state.modal,
      });
    }
    modal = () =>(
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerBlock}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="12">
                          <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.reason}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form92" className="form-control" onChange={this.changeHandlerBlock} required validate  name="reason"/>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon className="mr-2" />
                        {allStrings.block}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    submitHandlerPay = (event) => {
      event.preventDefault();
      event.target.className += " was-validated";
      this.payCommission()
    };
    changeHandlerPay = (event) => {
        console.log(event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };
    toggle2 = () => {
      this.setState({
        modal2: !this.state.modal2,
      });
    }
    modal2 = () =>(
      <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="lg">
        <MDBModalHeader toggle={this.toggle2} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerPay}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="12">
                          <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.money}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={0} id="form92" className="form-control" onChange={this.changeHandlerPay} required validate  name="money" defaultValue={this.state.money}/>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                        <MDBIcon className="mr-2" />
                        {allStrings.payCommission}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    payCommission = () => {
      let l = message.loading('Wait..', 2.5)
      let data ={balance:this.state.money}
      axios.post(`${BASE_END_POINT}${this.props.location.state.data.id}/payCommission`,data,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
      })
      .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        this.toggle2()
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.err, 2.5))
      })
   
    }
    called = () => {
      let l = message.loading('Wait..', 2.5)
      axios.put(`${BASE_END_POINT}${this.props.location.state.data.id}/called`,{},{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
      })
      .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.err, 2.5))
      })
   
    }
    addBalance = () => {
      if(this.state.cost > 0){
      let l = message.loading('Wait..', 2.5)
      let data = {
        'cost':this.state.cost
      }
      axios.put(`${BASE_END_POINT}/${this.props.location.state.data.id}/addBalance`,data,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`,
          'Accept-Language':this.props.isRTL?'ar':'en'
        },
      })
      .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
    }
    }
    changeHandlerAddBalance = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
    submitHandlerAddBalance = event => {
      event.preventDefault();
      console.log(event.target.className)
      event.target.className += " was-validated";
      this.addBalance()
    };
    modal3 = () =>(
      <MDBModal isOpen={this.state.modal3} toggle={this.toggle3} size="lg">
        <MDBModalHeader toggle={this.toggle3} >{allStrings.completeData}</MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <form style={{paddingTop:'1rem'}}
                    className="needs-validation"
                    onSubmit={this.submitHandlerAddBalance}
                    noValidate
                  >
                    <div className="grey-text">
                      <MDBRow>
                        <MDBCol md="12">
                          <div className="md-form">
                            <label htmlFor="form92" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.cost}</label>
                            <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="number" min={0} id="form92" className="form-control" onChange={this.changeHandlerAddBalance} required validate  name="cost" defaultValue={this.state.cost}/>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <MDBBtn type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                        {allStrings.add}
                      </MDBBtn>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
    toggle3 = () => {
      this.setState({
          modal3: !this.state.modal3,
      });
    }
    onChange = (date, dateString) =>{
      this.setState({birthday:dateString})
      console.log( dateString);
    }
  
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('USERS')) return <Redirect to='/Home' />

    return (
      <div className="App">
        <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#233b5b'}}>
          <MDBContainer>
          {this.modal()}
          {this.modal2()}
          {this.modal3()}
              <MDBRow>
                  <MDBCol xl ={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
                      <div className="infoCard">
                          <Card className="editCard">
                            
                              <CardHeader></CardHeader>
                              <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                  <div className="topIcon">
                                      <span className="material-icons" style={{color:'white',fontSize:23}}>group</span>
                                  </div>
                                  <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                  marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                                  <p>{this.state.actionType==="VIEW"?allStrings.userProfile:allStrings.edit}</p>
                                  </div>
                              </div>
                              <CardContent style={{minHeight:"390px",outline:'none'}}>
                              <MDBContainer>
                                  <MDBRow>
                                      <MDBCol md="12">
                                      <form
                                      className="needs-validation"
                                      onSubmit={this.submitHandler}
                                      noValidate
                                      >
                                          <div className="grey-text">
                                          {this.state.actionType==="VIEW"&&
                                          <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                              <MDBCol md="6">
                                                  <div className="md-form">
                                                  <label htmlFor="form08" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.userType}</label>
                                                  <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.user.type === "ADMIN"?allStrings.admin:this.state.user.type==="ISP"?allStrings.isp:this.state.type==="SUB-ADMIN"?allStrings.subAdmin:allStrings.client} type="text" id="form08" className="form-control" validate/>
                                                  </div>
                                              </MDBCol>
                                              <MDBCol md="6">
                                                  <div className="md-form">
                                                  <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                                  <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.id} type="text" id="form8" className="form-control"  validate/>
                                                  </div>
                                              </MDBCol>
                                          </MDBRow>
                                          }
                                          {this.state.actionType==="VIEW"&&
                                          <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                              <MDBCol md="6">
                                                  <div className="md-form">
                                                  <label htmlFor="form88" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.approved}</label>
                                                  <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.approved?allStrings.yes:allStrings.no} type="text" id="form88" className="form-control" validate/>
                                                  </div>
                                              </MDBCol>
                                              <MDBCol md="6">
                                                  <div className="md-form">
                                                  <label htmlFor="form18" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.block}</label>
                                                  <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.block?allStrings.yes:allStrings.no} type="text" id="form18" className="form-control"  validate/>
                                                  </div>
                                              </MDBCol>
                                          </MDBRow>
                                          }
                                          {this.state.actionType==="VIEW"&&
                                          <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                              <MDBCol md="6">
                                                  <div className="md-form">
                                                  <label htmlFor="form98" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.called}</label>
                                                  <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.called?allStrings.yes:allStrings.no} type="text" id="form98" className="form-control" validate/>
                                                  </div>
                                              </MDBCol>
                                              <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="formn8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.totalEarns}</label>
                                                <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.totalEarns} type="text" id="formn8" className="form-control" validate/>
                                                </div>
                                              </MDBCol>
                                          </MDBRow>
                                          }
                                          {this.state.actionType==="VIEW"&&
                                          <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                                              <MDBCol md="6">
                                                  <div className="md-form">
                                                  <label htmlFor="form58" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.earns}</label>
                                                  <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.earns} type="text" id="form58" className="form-control" validate/>
                                                  </div>
                                              </MDBCol>
                                              <MDBCol md="6">
                                                  <div className="md-form">
                                                  <label htmlFor="form78" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.Deals}</label>
                                                  <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.deals} type="text" id="form78" className="form-control"  validate/>
                                                  </div>
                                              </MDBCol>
                                          </MDBRow>
                                          }
                                        
                                          <MDBRow>
                                              <MDBCol md="6">
                                                  <div className="md-form">
                                                  <label htmlFor="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.email}</label>
                                                  <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^a-zA-Z0-9$@$!%*?&#^-_. +]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.email} type="email" id="form65" name="email" className="form-control" onChange={this.changeHandler} required validate/>
                                                  <div class="valid-feedback">{allStrings.correct}</div>
                                                  <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                  </div>
                                              </MDBCol>
                                              <MDBCol md="6">
                                                  <div className="md-form">
                                                  <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.name}</label>
                                                  <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.fullname} type="text" id="form605" name="fullname" className="form-control" onChange={this.changeHandler} required  validate/>
                                                  <div class="valid-feedback">{allStrings.correct}</div>
                                                  <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                  </div>
                                              </MDBCol>
                                          </MDBRow>
                                          
                                          <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form615" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.phone}</label>
                                                <input disabled={this.state.actionType==="VIEW"?true:false} onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.phone} type="text" id="form615" name="phone" className="form-control" onChange={this.changeHandler} required  validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            {this.state.actionType==="EDIT"?
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form625" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.password}</label>
                                                <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.password} type="text" id="form625" name="password" className="form-control" onChange={this.changeHandler}  validate/>
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            :
                                            <MDBCol md="6">
                                                <div className="md-form">
                                                <label htmlFor="form615" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.joinDate}</label>
                                                <input disabled={true} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={moment(this.state.createdAt).format('ll')} type="text" id="form615" name="createdAt" className="form-control" />
                                                <div class="valid-feedback">{allStrings.correct}</div>
                                                <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                </div>
                                            </MDBCol>
                                            }
                                          </MDBRow>
                                          
                                          <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}>
                                              <MDBCol md="6">
                                              <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.country}</label>
                                              <TreeSelect
                                              value= {this.state.country}
                                              showSearch={true} 
                                              style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                              treeCheckable = {false}    
                                              disabled={this.state.actionType==="VIEW"?true:false}     
                                              treeNodeFilterProp="title"                      
                                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                              placeholder={allStrings.country}
                                              showCheckedStrategy= {SHOW_PARENT}
                                              onChange={(value) => {
                                                  this.setState({country:value});
                                                  console.log(value)
                                              }} 
                                              
                                              >
                                                  {this.state.countries.map(val=>(
                                                      <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                                                  ))}
                                              </TreeSelect>
                                              </MDBCol>
                                              {this.state.type ==="SUB-ADMIN"?
                                                  <MDBCol md="6">
                                                  <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.permission}</label>
                                                  <TreeSelect 
                                                    value= {this.state.permission}
                                                    showSearch={true} 
                                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                                    treeCheckable = {false}    
                                                    disabled={this.state.actionType==="VIEW"?true:false}     
                                                    treeNodeFilterProp="title"                      
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder={allStrings.permission}
                                                    showCheckedStrategy= {SHOW_PARENT}
                                                    onChange={(value) => {
                                                        this.setState({permission:value});
                                                        console.log(value)
                                                    }} 
                                                  
                                                  >
                                                      {this.state.permissions.map(val=>(
                                                          <TreeNode value={val.id} title={this.props.isRTL?val.permission_ar:val.permission_en} key={val.id} />
                                                      ))}
                                                  </TreeSelect>
                                                  </MDBCol>
                                                  :
                                                  <MDBCol md="6">
                                                      <div className="md-form">
                                                      <label htmlFor="form28" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.yearsExperience}</label>
                                                      <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.yearsExperience} type="text" id="form28" className="form-control" validate/>
                                                      </div>
                                                  </MDBCol>
                                              }
                                          </MDBRow>
                                          <MDBRow  style={{flexFlow:this.props.isRTL?'row-reverse':'row'}}>
                                            <MDBCol md="6">
                                              <div className="md-form">
                                                <label htmlFor="form625" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.birthday}</label>
                                                <DatePicker disabled={this.state.actionType==="VIEW"?true:false} style={{width: '100%',marginTop:'15px'}} 
                                                onChange={this.onChange} format={'YYYY-MM-DD'} defaultValue={this.state.birthday?moment(this.state.birthday, 'YYYY-MM-DD'):""}/>
                                              </div>
                                            </MDBCol>
                                            <MDBCol md="6"></MDBCol>
                                          </MDBRow>
                                          {this.state.accountType ==="BLOCKED"&&
                                          <MDBRow>
                                            <MDBCol md="12">
                                              <div className="md-form">
                                                <label htmlFor="form092" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.reason}</label>
                                                <input disabled value={this.state.blockReason}  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} type="text" id="form092" className="form-control"  name="blockReason"/>
                                              </div>
                                            </MDBCol>
                                          </MDBRow>
                                          }
                                          
                                          {this.state.actionType==="EDIT"&&
                                          <MDBRow>
                                              <MDBCol md="12">
                                              <label htmlFor="form8930" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.profileImg}</label>

                                                  <div className="fileUpload2"> 
                                                      <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                      <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                  </div>
                                                  <div className="preview">
                                                  {this.state.img&&
                                                  <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                      <img crossorigin="anonymous"alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img} className="previewImg2"/>
                                                      
                                                      <span className="imgName">{this.state.imgName}</span>
                                                      <MDBIcon icon="trash" className="mr-2 removeImg3"
                                                      onClick={() => this.removeFile()}  ></MDBIcon>
                                                  </div>
                                                  }
                                                  </div>
                                                  
                                              </MDBCol>
                                          </MDBRow>
                                          }
                                          </div>
                                          <div style={{textAlign: 'center',marginTop:'30px'}}> 
                                              {this.state.type==="ISP" && this.state.actionType==="VIEW"&&
                                              <Tooltip title={allStrings.Deals} placement="top">
                                                <span onClick={()=>{this.props.history.push("Deals",{owner:this.state.id}) }}  
                                                className="material-icons" style={{color:'#233b5b',fontSize:50,cursor:'pointer'}}>content_paste</span>
                                              </Tooltip>
                                              }
                                          </div>
                                          <br></br>
                                          <br></br>
                                          <div className="text-center">
                                          {this.state.actionType==="EDIT"&&
                                            <MDBBtn
                                            type="submit"
                                            rounded
                                            className="btn-block z-depth-1a"
                                            >
                                                <MDBIcon icon="edit" className="mr-2" />
                                                {allStrings.update}        
                                            </MDBBtn>
                                          }{this.state.actionType==="VIEW"&&
                                            <MDBBtn onClick={()=>{this.toggle3()}} rounded color="green" className="btn-block z-depth-1a">
                                                <MDBIcon  className="mr-2" />
                                                {allStrings.addBalance}
                                            </MDBBtn>
                                          }
                                          
                                        </div>
                                      </form>
                                      </MDBCol>
                                      
                                  </MDBRow>
                              </MDBContainer>
                              </CardContent>
                          </Card>
                      </div>
                    </MDBCol>
                    {this.state.actionType==="VIEW"&&this.imageSection()}    
              </MDBRow>
              {this.state.actionType==="VIEW"&&this.buttonsSection()}
              
              
          </MDBContainer>
        </Spin>
      </div> 
    )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(UserInfo)
);