import React from 'react';
import './add guideline.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message,Progress} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import ResultDialog from "../../component/result/request"

class AddGuideline extends React.Component {
    state = {
        title:'',
        description:'',
        video: null,
        videoType:'data',
        videoName:'',
        thumbnail: null,
        thumbnailType:'data',
        thumbnailName:'',
        
        messageType:'success',
        showDialog:false,
        details:'',
        progress:80,
        waitResponse:false,
      };

      constructor(props){
        super(props)
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        window.scrollTo(0,0)
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        } 
      }
      componentDidMount(){
        if(this.props.isRTL){
          allStrings.setLanguage('ar')
        }else{
          allStrings.setLanguage('en')
        }
      }

      addGuideline = () => {
        const {title,video,description,thumbnail} = this.state
        let l = message.loading('Wait..', 1.5)
        if(video === null ||thumbnail == null)
          l.then(() => message.error(allStrings.completeData, 1.5) )
        if(hasValue(title)){
           
            var data = new FormData()
            data.append('title',title)
            if(hasValue(description))
              data.append('description',description)
            
            data.append(`video`,video )
            data.append('thumbnail',thumbnail)
            console.log(Array.from(data))
            axios.post(`${BASE_END_POINT}guidelines`,data,{
              headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization':`Bearer ${this.props.currentUser.token}`,
              'Accept-Language':this.props.isRTL?'ar':'en'
              },
            })
            .then(response=>{
              this.setState({
                  showDialog:true,messageType:'success'
              })
            })
            .catch(error=>{
                console.log(error.response)
                if(error.response.data.errors){
                    this.setState({
                        showDialog:true,
                        messageType:'error',
                        details:error.response.data.errors.msg[0].msg
                    })
                }else{
                this.setState({showDialog:true,messageType:'error'})
                }
                this.setState({waitResponse:false,progress:50})
            })
        }
     }
     
      submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.addGuideline()
      };
    
      changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
      };

     
      fileChangedHandler = event => {
        this.setState({
        video: event.target.files[0],
        videoName: event.target.files[0].name,
        videoType:'data'
        })
      }
      removeFile = () => {
        this.setState({
          video:null,
          videoName:'',
          videoType:"url"
        });
      }
      fileChangedHandlerThumbnail = event => {
        this.setState({
          thumbnail: event.target.files[0],
          thumbnailName: event.target.files[0].name,
          thumbnailType:'data'
        })
      }
      removeFileThumbnail = () => {
        this.setState({
          thumbnail:null,
          thumbnailName:'',
          thumbnailType:"url"
        });
      }
      
   
     
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('GUIDELINES')) return <Redirect to='/Home' />


  return (
    <div className="App">
        
        <MDBContainer>
            {this.state.showDialog &&
                <ResultDialog type={this.state.messageType} message={this.state.messageType ==="success"?allStrings.done:allStrings.error} details = {this.state.details}/>
            }
            <MDBRow>
                <MDBCol xl ="12" lg = "12" md="12" sm="12">
                    <div className="infoCard">
                        <Card className="editCard">
                        <CardMedia
                        style={{height:"0px"}}
                        />
                        <CardHeader></CardHeader>
                        <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                            <div className="topIcon">
                                <span class="material-icons" style={{color:'white',fontSize:23}}>rss_feed</span>
                            </div>
                            <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                            marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                            <p>{allStrings.add}</p>
                            </div>
                        </div>
                        <CardContent style={{minHeight:"390px",outline:'none'}}>
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md="12">
                                <form
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate
                                >
                                    <div className="grey-text">
                                        {this.state.waitResponse&&
                                            <Progress percent={this.state.progress} status="active" showInfo={false} />
                                        }
                                        <MDBRow>
                                          <MDBCol md="12">
                                            <div className="md-form">
                                              <label htmlFor="form38" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.title}</label>
                                              <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.title} type="text" id="form38"  name="title"  className="form-control" onChange={this.changeHandler} required validate/>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                            </div>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol md="12">
                                            <div className="md-form">
                                              <label htmlFor="form7" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.description}</label>
                                              <br></br>
                                              <div class="valid-feedback">{allStrings.correct}</div>
                                              <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                              <textarea disabled={this.state.actionType==="VIEW"?true:false}  rows="3" style={{border:'none',borderBottom:'1px solid #dededede',direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center',padding:'.3rem 7px .4rem 7px'}} 
                                              defaultValue={this.state.description} type="text" id="form7" className="form-control" onChange={this.changeHandler}  validate name="description"/>
                                            </div>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol md="12">
                                              <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.video}</label>

                                              <div className="fileUpload2"> 
                                                  <input  key={this.state.video} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                  <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.uploadVideo}</span></span>
                                              </div>
                                              <div className="preview">
                                              {this.state.video&&
                                              <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                <video className="previewVideo2" controls>
                                                    <source src={this.state.videoType==="data"?URL.createObjectURL(this.state.video):this.state.video} type="video/mp4"/>
                                                    <source src={this.state.videoType==="data"?URL.createObjectURL(this.state.video):this.state.video} type="video/ogg"/>
                                                    Your browser does not support the video tag.
                                                </video>
                                                  <span className="videoName">{this.state.videoName}</span>
                                                  <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                  onClick={() => this.removeFile()}  ></MDBIcon>
                                              </div>
                                              }
                                              </div>
                                              
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md="12">
                                                <label for="form830" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.thumbnail}</label>

                                                <div className="fileUpload2"> 
                                                    <input  key={this.state.thumbnail} type='file' className="upImgs2" onChange={this.fileChangedHandlerThumbnail} ></input>
                                                    <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                </div>
                                                <div className="preview">
                                                {this.state.thumbnail&&
                                                <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                    <img crossorigin="anonymous"alt="" src={this.state.thumbnailType==="data"?URL.createObjectURL(this.state.thumbnail):this.state.thumbnail}  className="previewImg2"/>
                                                    <span className="imgName">{this.state.thumbnailName}</span>
                                                    <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                    onClick={() => this.removeFileThumbnail()}  ></MDBIcon>
                                                </div>
                                                }
                                                </div>
                                                
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <div className="text-center">
                                        <MDBBtn
                                          type="submit"
                                          rounded
                                          className="btn-block z-depth-1a"
                                        >
                                            <MDBIcon icon="plus" className="mr-2" />
                                            {allStrings.add}        
                                        </MDBBtn>
                                    </div>
                                </form>
                                </MDBCol>
                                
                            </MDBRow>
                        </MDBContainer>
                        </CardContent>
                    </Card>
                </div>
            </MDBCol>
            </MDBRow>
            
            
        </MDBContainer>
      
    </div> 
  )
  }
}



const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  });
  
  const mapDispatchToProps = {
  };
  
  export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(AddGuideline)
  );