import React from 'react';
import './home.css'
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";

import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter,NavLink} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import Skeleton from 'react-loading-skeleton';
import moment from 'moment'
import { Spin } from 'antd';
//import locale_ar from 'antd/lib/locale/ar_EG';
//import locale_en from 'antd/lib/locale/en_GB';
//import 'moment/locale/ar';


class Home extends React.Component {
  
  state = {
    count:[],
    tops:[],
    topUsers:[],
    Bills:[],
    user:[],
    deals:[],
    loading:true,
    clients:[],
    graphdeals:[],
    days:[],
    daysEN:[],
    daysAR:[],
    maxValue:0,
    graphStep:0,
   
  }
  
   
  constructor(props){
    super(props)
    this.getCount()
    this.getDeals()
    //this.getGraph()
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
      moment.locale('ar');
    }else{
      allStrings.setLanguage('en')
      moment.locale('en');
    } 

  }
  getGraph = () => {
    axios.get(`${BASE_END_POINT}admin/getGraph`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log(response.data)
      let daysEN=[]
      let daysAR=[]
      response.data.days.forEach(day => {
        daysAR.push(moment(day).locale('ar').format("ll"))
        daysEN.push(moment(day).locale('en').format('MMM Do YY'))
        //dayA.push(dayNameA(day))
      });
      let arr = response.data.clients
      let maxValue1 = Math.max( ...arr );

      let max = maxValue1;

      let arr2 = response.data.deals
      let maxValue2 = Math.max( ...arr2 );
      if(maxValue2 > maxValue1)
        max = maxValue2

       
      this.setState({
        loading: false,
        clients:response.data.clients,
        graphdeals:response.data.deals,
        daysEN:daysEN,
        daysAR:daysAR,
        maxValue:max,
        graphStep:Math.floor(max / 10)
      })
    })
    .catch(error=>{
      console.log("ALL graph ERROR")
      console.log(error.response)
    })
  }
  getCount = () => {
    axios.get(`${BASE_END_POINT}admin/count`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
    .then(response=>{
      console.log(response.data)
      this.setState({count:response.data,loading:false,topUsers:response.data.topUsers})
    })
    .catch(error=>{
      console.log("ALL count ERROR")
      console.log(error.response)
    })
  }
  getDeals = () => {
    axios.get(`${BASE_END_POINT}deals?page=1&limit=7`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log(response.data.data)
      this.setState({deals:response.data.data})
    })
    .catch(error=>{
      console.log("ALL deals ERROR")
      console.log(error.response)
    })
  }

  componentDidMount(){
    //this.props.ChangeLanguage(false)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
      moment.locale('ar');
    }else{
      allStrings.setLanguage('en')
      moment.locale('en');
    } 
  
  }

  render(){
    let {loading,topUsers} = this.state;
  return (
    <div className="Home">
      <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#233b5b'}}>
      <MDBContainer> 
        <MDBRow>
          
         <div style={{textAlign:this.props.isRTL? 'right':'left',
         flexDirection:this.props.isRTL?'row':'row-reverse',
         display:this.props.isRTL?'': 'flex',
         width:this.props.isRTL?'100%': '',padding: '5px 20px 25px'}}>
          <span  style={{
            display: 'inline-block',
            color: '#43425D',
            fontSize:'22px'}}>{allStrings.statistic}</span>
          <span  style={{fontFamily:'system-ui',fontWeight:'bold',fontSize:'22px',display: 'inline-block',
            color: '#43425D',margin:'6px',lineHeight:this.props.isRTL?'auto':'15px'}}>|</span>
          <span style={{fontFamily:'system-ui',fontWeight:'bold',fontSize:'22px',display: 'inline-block',
            color: '#43425D'}}>{allStrings.overView}</span>
         </div>
         
        </MDBRow>
        <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>

          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.ispCount}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img crossorigin="anonymous"alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart1.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.ispCount}</p>
                  
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.acceptedDeals}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img crossorigin="anonymous"alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart2.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.acceptedDeals}</p>
                    
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.dealCount}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img crossorigin="anonymous"alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart3.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.dealCount}</p>
                    
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.pendingDeals}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img crossorigin="anonymous"alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart4.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.pendingDeals}</p>
                   
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.adminCount}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img crossorigin="anonymous"alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart5.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.adminCount}</p>
                    
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.propertyCount}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img crossorigin="anonymous"alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart6.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.propertyCount}</p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
       
      
        <br></br>
        <br></br>
        
        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
          <MDBCol xl="8" lg="7" md="12" sm="12">
            <div className="theCard" style={{minHeight:'505px'}}>
              <p className="topCard" style={{color: '#43425D',textAlign: this.props.isRTL?'right':'left'}}>{allStrings.Deals}</p>
              <br></br>
              <table className="table table-borderless">
                <thead className="thead-light">
                  <tr >
                    <th scope="col">{allStrings.clientName}</th>
                    <th scope="col">{allStrings.salesName}</th>
                    <th scope="col">{allStrings.value}</th>
                    <th scope="col">{allStrings.status}</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.loading?
                
                <div style={{width:"100%"}} >
                  <Skeleton height={40} /> 
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                </div>
                :
                this.state.deals.length > 0 ?
                  this.state.deals.map(val=>(
                      <tr onClick={()=>this.props.history.push("/dealInfo",{data:val})}>
                        
                        <td>{val.clientName?val.clientName:""}</td>
                        <td>{val.salesName?val.salesName:""}</td>
                        <th>{val.value?val.value:""}</th>
                        <th>{val.status==="PENDING"?allStrings.pending:val.status==="ACCEPTED"?allStrings.accepted:allStrings.refused}</th>
                      </tr>
                  ))
                  :
                  <tr style={{height: '300px'}}>
                      <th colSpan="8" className="noData" style={{background:"#fff"}}>
                      <p style={{marginTop:'10rem',textAlign:'center',}}>{allStrings.noData}</p> 
                      </th>
                  </tr>
                }
                </tbody>
              </table>
              <hr></hr>
              <NavLink to="Deals">
                <span style={{display:'block',marginBottom:'8px'}}>{allStrings.showAll}</span>
              </NavLink>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="5" md="12" sm="12">
            <div className="theCard" style={{minHeight:'505px'}}>
              <NavLink to="users">
                <span style={{position: 'absolute'}}>{allStrings.showAll}</span>
              </NavLink>
              <p className="topCard"  style={{color: '#43425D'}}>{allStrings.topUsers}</p>
              <img crossorigin="anonymous"alt="" width="100%" src={require('../../assets/images/Map.png')}></img>
              <div>
                <span className="rightSpan">{topUsers[0]?topUsers[0].fullname?topUsers[0].fullname.substr(0,10):"-":"-"}</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{color:'#54D8FF'}}></span>
                  <span className="spanNum">{topUsers[0]?topUsers[0].earns?topUsers[0].earns:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topUsers[1]?topUsers[1].fullname?topUsers[1].fullname.substr(0,10):"-":"-"}</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#5EE2A0'}}></span>
                  <span className="spanNum">{topUsers[1]?topUsers[1].earns?topUsers[1].earns:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topUsers[2]?topUsers[2].fullname?topUsers[2].fullname.substr(0,10):"-":"-"}</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FFA177'}}></span>
                  <span className="spanNum">{topUsers[2]?topUsers[2].earns?topUsers[2].earns:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topUsers[3]?topUsers[3].fullname?topUsers[3].fullname.substr(0,10):"-":"-"}</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#A3A1FB'}}></span>
                  <span className="spanNum">{topUsers[3]?topUsers[3].earns?topUsers[3].earns:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topUsers[4]?topUsers[4].fullname?topUsers[4].fullname.substr(0,10):"-":"-"}</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FBA69D'}}></span>
                  <span className="spanNum">{topUsers[4]?topUsers[4].earns?topUsers[4].earns:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topUsers[5]?topUsers[5].fullname?topUsers[5].fullname.substr(0,10):"-":"-" }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FF7CC3'}}></span>
                  <span className="spanNum">{topUsers[5]?topUsers[5].earns?topUsers[5].earns:"-":"-"}</span>
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer> 
      </Spin>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Home)
);
