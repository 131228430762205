
import React, { useState, useEffect } from 'react';
import './menu.css'
import {
  HomeOutlined,
  TeamOutlined,
  AuditOutlined,
  GlobalOutlined,
  UserOutlined,
  ApartmentOutlined,
  InfoCircleOutlined,
  FundProjectionScreenOutlined,
  NotificationOutlined,
  QuestionCircleOutlined,
  FlagOutlined,
  ControlOutlined,
  EyeOutlined ,
  IdcardOutlined,
  LockOutlined,
  ReadOutlined,
  DesktopOutlined,
  AppleOutlined,
  GiftOutlined
} from '@ant-design/icons';
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {setUser} from '../../redux/actions/AuthActions'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import {Collapsed} from '../../redux/actions/MenuAction'

const { Sider } = Layout;
const { SubMenu } = Menu;

export const SiderMenu = ({isRTL,currentUser,setUser,ChangeLanguage,Collapsed,menu}) => {
  const [collapsed, setCollapsed] = useState(menu);
  const location = useLocation();
console.log(collapsed)
  // Similar to componentDidMount and componentDidUpdate:
  if(isRTL){
    allStrings.setLanguage('ar')
  }else{
    allStrings.setLanguage('en')
  } 
  

  useEffect(() => {
    if(isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  });
  const onCollapse = (collapsed) =>{ setCollapsed(collapsed);Collapsed(collapsed);localStorage.setItem("@MENU",collapsed)};
  return (
    <Sider 
    style={{
      overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left:0
        //left: isRTL?'auto':0,
        //right: isRTL?0:'auto',
        //direction:isRTL?'rtl':'ltr'
    }}
    collapsible collapsed={collapsed} onCollapse={onCollapse} >
      {!collapsed?
      <div className="logo" >
        <img crossorigin="anonymous"alt="" style={{width: '26%',position: 'relative',top:isRTL?'-5px':'-5px',left:'55px'}} src={require('../../assets/images/logo2.png')} ></img>
        <p style={{letterSpacing:'6px',color:'#fff',fontSize:isRTL?'34px':'26px',fontStyle: 'oblique',
            fontWeight: '800',fontFamily: 'fantasy',display:'inline-block',marginLeft:isRTL?'10px':'0px',marginTop:isRTL?'-10px': 0}}></p>
      </div>
      :
      <div className="logo" >
        <img crossorigin="anonymous"alt="" style={{width: '88%',position: 'relative',top: '-6px',left:'0px'}} src={require('../../assets/images/logo2.png')} ></img>       
      </div>      
      }
      
      
      
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
        
         {/*Home */}
         {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Home">
              <HomeOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}} />
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.home}</span>
              <Link to="/Home"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('DASHBOARD')&&
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Home">
              <HomeOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}} />
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.home}</span>
              <Link to="/Home"></Link>
            </Menu.Item>
          }
            {/*categories */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Categories"> 
            <ApartmentOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.categories}</span>
              <Link to="/Categories"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('CATEGORIES')&&
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Categories"> 
            <ApartmentOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.categories}</span>
              <Link to="/Categories"></Link>
            </Menu.Item>
            }
        
        <SubMenu  key="sub2" icon={<UserOutlined style={{/*
            float:isRTL? 'right':'none',
            position:isRTL?'absolute':'relative',
            right:isRTL?collapsed?28:45:'auto'*/
            }} />} title={allStrings.users}>
            {/*CLIENTS */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Users" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{marginRight:isRTL? '6px':'0px'}}>{allStrings.users}</span>
              <Link to="/Users"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('USERS')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Users" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{marginRight:isRTL? '6px':'0px'}}>{allStrings.users}</span>
              <Link to="/Users"></Link>
            </Menu.Item>
            }
            {/*SUB-ADMIN */}
            {currentUser.user.type ==="ADMIN" ?
            
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Supervisors" > 
            <TeamOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.supervisors}</span>
              <Link to="/Supervisors"></Link>
            </Menu.Item>
              :
              currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('SUB-ADMIN')&&
              
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Supervisors" > 
            <TeamOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.supervisors}</span>
              <Link to="/Supervisors"></Link>
            </Menu.Item>
            }
            
          {currentUser.user.type ==="ADMIN" &&
          <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Admins" > 
          <TeamOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
              <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.admin}</span>
            <Link to="/Admins"></Link>
          </Menu.Item>

          }
          {/*SUB-ADMIN */}
          {currentUser.user.type ==="ADMIN" ?
            
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/addUser" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.AddUser}</span>
              <Link to="/addUser"></Link>
            </Menu.Item>
              :
              currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('ADD-USER')&&
              
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/addUser" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.AddUser}</span>
              <Link to="/addUser"></Link>
            </Menu.Item>
            }
        </SubMenu>

        
        {/*Locations */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Locations"> 
         <GlobalOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.locations}</span>
           <Link to="/Locations"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('LOCATIONS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Locations"> 
        <GlobalOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.locations}</span>
          <Link to="/Locations"></Link>
        </Menu.Item>
        }
        {/*ahya team */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/byitTeam"> 
         <TeamOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.byitTeam}</span>
           <Link to="/byitTeam"></Link>
         </Menu.Item>
         
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('TEAMS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/byitTeam"> 
        <TeamOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.byitTeam}</span>
          <Link to="/byitTeam"></Link>
        </Menu.Item>
        }
        {/*anoncements */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/anoncements"> 
         <DesktopOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.anoncements}</span>
           <Link to="/anoncements"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('ANONCEMENTS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/anoncements"> 
        <DesktopOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.anoncements}</span>
          <Link to="/anoncements"></Link>
        </Menu.Item>
        }
        {currentUser.user.type ==="ADMIN" ?
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Companies"> 
          <AppleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.companies}</span>
            <Link to="/Companies"></Link>
          </Menu.Item>
          :
          currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('COMPANIES')&&
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Companies"> 
          <AppleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.companies}</span>
            <Link to="/Companies"></Link>
          </Menu.Item>
        }
        {/*compounds */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Compounds"> 
         <FundProjectionScreenOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.compounds}</span>
           <Link to="/Compounds"></Link>
         </Menu.Item>
         
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('COMPOUNDS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Compounds"> 
        <FundProjectionScreenOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.compounds}</span>
          <Link to="/Compounds"></Link>
        </Menu.Item>
        }
        {/*Clients */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Clients"> 
         <IdcardOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Clients}</span>
           <Link to="/Clients"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('CLIENTS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Clients"> 
        <IdcardOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Clients}</span>
          <Link to="/Clients"></Link>
        </Menu.Item>
        }
        {/*COUNTRIES */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Countries"> 
        <FlagOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Countries}</span>
          <Link to="/Countries"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('COUNTRIES')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Countries"> 
        <FlagOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Countries}</span>
          <Link to="/Countries"></Link>
        </Menu.Item>
        }
        {/*Properties */}
        {currentUser.user.type ==="ADMIN" ?
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Properties"> 
          <ApartmentOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Properties}</span>
            <Link to="/Properties"></Link>
          </Menu.Item>
          :
          currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('PROPERTIES')&&
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Properties"> 
          <ApartmentOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Properties}</span>
            <Link to="/Properties"></Link>
          </Menu.Item>
        }
        {/*NEW LAUNCH */}
        {currentUser.user.type ==="ADMIN" ?
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/NewLaunches"> 
          <ApartmentOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.newLaunches}</span>
            <Link to="/NewLaunches"></Link>
          </Menu.Item>
          :
          currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('NEW-LAUNCHES')&&
          <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/NewLaunches"> 
          <ApartmentOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.newLaunches}</span>
            <Link to="/NewLaunches"></Link>
          </Menu.Item>
        }
        {/*Deals */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Deals"> 
         <AuditOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Deals}</span>
           <Link to="/Deals"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('DEALS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Deals"> 
        <AuditOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Deals}</span>
          <Link to="/Deals"></Link>
        </Menu.Item>
        }
        {/*views */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/views"> 
         <EyeOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.views}</span>
           <Link to="/views"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('VIEWS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/views"> 
        <EyeOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.views}</span>
          <Link to="/views"></Link>
        </Menu.Item>
        }
        {/*ABOUT */}
          {currentUser.user.type ==="ADMIN" ?
          <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/about"> 
          <InfoCircleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.about}</span>
            <Link to="/about"></Link>
          </Menu.Item>
          :
          currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('ABOUT')&&
          <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/about"> 
            <InfoCircleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.about}</span>
              <Link to="/about"></Link>
          </Menu.Item>
        }
        {/*FAQ */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Questions"> 
        <QuestionCircleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.questions}</span>
          <Link to="/Questions"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('FAQ')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Questions"> 
        <QuestionCircleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.questions}</span>
          <Link to="/Questions"></Link>
        </Menu.Item>
        }
        {/*SETTINGS */}
        {currentUser.user.type ==="ADMIN" ?
          <Menu.Item style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Setting"> 
          <ControlOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
              <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.setting}</span>
            <Link to="/Setting"></Link>
          </Menu.Item>
          :
          currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('SETTINGS')&&
          <Menu.Item style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Setting"> 
            <ControlOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.setting}</span>
              <Link to="/Setting"></Link>
          </Menu.Item>
        }
        {/*PRIZES */}
        {currentUser.user.type ==="ADMIN" ?
          <Menu.Item style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Prizes"> 
          <GiftOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
              <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.prizes}</span>
            <Link to="/Prizes"></Link>
          </Menu.Item>
          :
          currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('PRIZES')&&
          <Menu.Item style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Prizes"> 
            <GiftOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.prizes}</span>
              <Link to="/Prizes"></Link>
          </Menu.Item>
        }
        {/*NOTIFS */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/SendNotifs"> 
        <NotificationOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.sendNotif}</span>
          <Link to="/SendNotifs"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.data.permission.pages.includes('NOTIFS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/SendNotifs"> 
        <NotificationOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.sendNotif}</span>
          <Link to="/SendNotifs"></Link>
        </Menu.Item>
        }
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Guidelines"> 
        <ReadOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.guidelines}</span>
          <Link to="/Guidelines"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('GUIDELINES')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Guidelines"> 
        <ReadOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.guidelines}</span>
          <Link to="/Guidelines"></Link>
        </Menu.Item>
        }
        {/*PERMISSIONS */}
        {currentUser.user.type ==="ADMIN" ?
          <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Permissions"> 
          <LockOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.permissions}</span>
            <Link to="/Permissions"></Link>
          </Menu.Item>
          :
          currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('PERMISSIONS')&&
          <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Permissions"> 
            <LockOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.permissions}</span>
              <Link to="/Permissions"></Link>
            </Menu.Item>
          }
        <Menu.Item></Menu.Item>
        <Menu.Item></Menu.Item>
        <Menu.Item></Menu.Item>
        
        
      </Menu>
      
    </Sider>
  );
};
const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  menu: state.menu.menu,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  Collapsed
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(SiderMenu)
);


