/* eslint-disable eqeqeq */
import React from "react";
import "./anoncement info.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import "react-image-gallery/styles/css/image-gallery.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import "react-rater/lib/react-rater.css";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter,Redirect } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { message,Image,TreeSelect} from "antd";
import { UploadOutlined } from '@ant-design/icons';
const { TreeNode } = TreeSelect;

class anoncementInfo extends React.Component {
  
  state = {
    actionType:this.props.location.state.actionType?this.props.location.state.actionType==="EDIT"?"EDIT":"VIEW":"VIEW",
    id:this.props.location.state.data.id,
    type:this.props.location.state.data.type,
    newLaunch:this.props.location.state.data.newLaunch?this.props.location.state.data.newLaunch:null,
    property:this.props.location.state.data.property?this.props.location.state.data.property:null,
    img:this.props.location.state?this.props.location.state.data.img !== ""?this.props.location.state.data.img:null:null,
    imgType:'url',
    imgName:'',
    loading:true,
    refresh:false,
    page:1,
    pages:1,
  };

  constructor(props){
    super(props)

    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }
  
  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  update = () => {
    const {img,imgType,type,newLaunch,property} = this.state
   
    if(type){
      let l = message.loading('Wait..', 1.5)
        var data = new FormData()
        if(img !== null &&imgType =='data'){
            data.append(`img`,img )
        }
        if(type === "NEW-LAUNCH"){
          data.append('newLaunch',newLaunch); 
        }
        if(type === "PROPERTY"){
          data.append('property',property); 
        }
        console.log(Array.from(data))
        
        axios.put(`${BASE_END_POINT}anoncements/${this.props.location.state.data.id}`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/anoncements')
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.error, 2.5))
        })
    }
  }
  

  delete = () => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}anoncements/${this.props.location.state.data.id}`,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.props.currentUser.token}`,
      'Accept-Language':this.props.isRTL?'ar':'en'
    },
    })
    .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
      this.props.history.goBack()
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
  }

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.update()
  };
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  fileChangedHandler = event => {
    this.setState({
      img: event.target.files[0],
      imgName: event.target.files[0].name,
      imgType:'data'
    })
  }
  removeFile = () => {
    this.setState({
        img:null,
        imgName:'',
        imgType:"url"
    });
  }

  imageSection = () =>{
      
    return(
      <MDBCol  xl ="4" lg = "5"  md="12" sm="12">
          <div className="infoCard">
              <div style={{width:"100%" ,padding:'10px'}}>
                <Image
                  width="100%"
                  style={{borderRadius:'6px',width:"100%",height:'280px'}} 
                  src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.props.location.state.data.img}
                />
              </div>
              <MDBBtn onClick={()=>{this.delete()}} rounded color="danger" className="btn-block z-depth-1a">
                    <MDBIcon icon="trash-alt" className="mr-2" />
                   {allStrings.remove}
              </MDBBtn>
          </div>
      </MDBCol>
        )
  }

  render() {
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('ANONCEMENTS')) return <Redirect to='/Home' />
    return (
      <div className="App">
        <MDBContainer>
          <MDBRow>
            <MDBCol xl={this.state.actionType==="VIEW"?"8":"12"} lg = {this.state.actionType==="VIEW"?"7":"12"} md="12" sm="12">
              <div className="infoCard">
                <Card className="editCard">
                  <CardMedia style={{ height: "0px" }} />
                  <CardHeader></CardHeader>
                  <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                        <div className="topIcon">
                            <span className="material-icons" style={{color:'white',fontSize:23}}>featured_video</span>
                        </div>
                        <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                        marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                        <p>{this.state.actionType==="VIEW"?allStrings.info:allStrings.edit}</p>
                        </div>
                    </div>
                  <CardContent style={{ minHeight: "390px", outline: "none" }}>
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandler}
                            noValidate
                          >
                            <div className="grey-text">
                            {this.state.actionType==="VIEW"&&
                              <MDBRow>
                                <MDBCol md="12">
                                  <div className="md-form">
                                    <label htmlFor="form8" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>#</label>
                                    <input disabled style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} defaultValue={this.state.id} type="text" id="form8" className="form-control" onChange={this.changeHandler} required validate/>
                                    <div class="valid-feedback">{allStrings.correct}</div>
                                    <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                            }

                              <MDBRow>
                                <MDBCol md="12">
                                  <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                                  <TreeSelect 
                                    disabled={this.state.actionType==="VIEW"?true:false}
                                    value= {this.state.type}
                                    virtual={false}
                                    showSearch={false} 
                                    style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'35px',marginBottom:'1rem'}} 
                                    treeCheckable = {false}         
                                    treeNodeFilterProp="title"                      
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder={allStrings.type}
                                    onChange={(value) => {
                                        console.log(value)
                                        this.setState({type:value});
                                    }} 
                                  >
                                      <TreeNode value="NORMAL" title={allStrings.normalAnoncement} key="NORMAL" />
                                      <TreeNode value="NEW-LAUNCH" title={allStrings.newLaunch} key="NEW-LAUNCH" />
                                      <TreeNode value="PROPERTY" title={allStrings.Properties} key="PROPERTY" />
                                                
                                  </TreeSelect>
                                </MDBCol>
                              </MDBRow>

                              {this.state.type === "PROPERTY" &&
                                <MDBRow>
                                  <MDBCol md="12">
                                    <div className="md-form">
                                      <label htmlFor="form6025" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.propertyId}</label>
                                      <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.property} name="property" type="text" id="form6025"  className="form-control" onChange={this.changeHandler} required validate />
                                      <div class="valid-feedback">{allStrings.correct}</div>
                                      <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                              }{this.state.type === "NEW-LAUNCH" &&
                                <MDBRow>
                                  <MDBCol md="12">
                                    <div className="md-form">
                                      <label htmlFor="form6025" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.newLaunchId}</label>
                                      <input disabled={this.state.actionType==="VIEW"?true:false} style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.newLaunch} name="newLaunch" type="text" id="form6025"  className="form-control" onChange={this.changeHandler} required validate/>
                                      <div class="valid-feedback">{allStrings.correct}</div>
                                      <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                              }       
                            
                            {this.state.actionType==="EDIT"&&
                              <MDBRow>
                                <MDBCol md="12">
                                    <div className="fileUpload2"> 
                                        <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                        <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                    </div>
                                    <div className="preview">
                                    {this.state.img&&
                                    <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                        <img alt="" src={this.state.imgType==="data"?URL.createObjectURL(this.state.img):this.state.img}  className="previewImg2"/>
                                        <span className="imgName">{this.state.imgName}</span>
                                        <MDBIcon icon="trash" className="mr-2 removeImg2"
                                        onClick={() => this.removeFile()}  ></MDBIcon>
                                    </div>
                                    }
                                    </div>
                                    
                                </MDBCol>
                              </MDBRow>
                            }
                            </div>
                            <br></br>
                            <br></br>
                            {this.state.actionType==="EDIT"&&
                              <div className="text-center">
                                <MDBBtn type="submit" style={{ background: '#233b5b' }} rounded className="btn-block z-depth-1a" >
                                  <MDBIcon icon="edit" className="mr-2" />
                                  {allStrings.update}
                                </MDBBtn>
                              </div>
                            }
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </CardContent>
                </Card>
              </div>
            </MDBCol>
            {this.state.actionType==="VIEW"&&this.imageSection()}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
});

const mapDispatchaddressrops = {
  setUser,
};

export default withRouter(
  connect(mapToStateProps, mapDispatchaddressrops)(anoncementInfo)
);