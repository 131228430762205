import React from 'react';
import './add country.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter ,Redirect} from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { hasValue } from "../../validations/validations";
import { message } from "antd";
import { UploadOutlined } from '@ant-design/icons';

class Addcountry extends React.Component {
    state = {
        name_en:'',
        name_ar:'',
        isoCode:'',
        numbersCount:'',
        countryCode:'',
        imgName:'',
        hint:'',
        img:null,
        selectedFile: null,
        imagePreviewUrl:[],
    };
    constructor(props){
        super(props)
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }
    componentDidMount(){
        if(this.props.isRTL){
            allStrings.setLanguage('ar')
        }else{
            allStrings.setLanguage('en')
        } 
    }

    add = () => {
        const {name_en,name_ar,isoCode,numbersCount,countryCode,img,hint} = this.state
        let l = message.loading('Wait..', 1.5)
        if(img == null){
            l.then(() => message.success(allStrings.completeData, 1.5) )
        }
        if(hasValue(name_en)&&hasValue(name_ar)&&hasValue(isoCode)
        &&hasValue(numbersCount)&&hasValue(countryCode)&&hasValue(hint)){
            var data = new FormData()
            data.append('name_ar',name_ar)
            data.append('name_en',name_en)
            data.append('isoCode',isoCode)
            data.append('countryCode',countryCode)
            data.append('numbersCount',numbersCount)
            data.append('hint',hint)
            if(img){
                data.append('img',img); 
            }
            axios.post(`${BASE_END_POINT}countries`,data,{
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.props.currentUser.token}`
            },
            })
            .then(response=>{
            console.log("country UPDATED  ",response.data)
            l.then(() => message.success(allStrings.done, 2.5) )
            this.props.history.goBack() 
            })
            .catch(error=>{
                console.log(error.response)
                l.then(() => message.error(allStrings.error, 2.5))
            })
        }
    }
    fileChangedHandler = event => {
        this.setState({
            img: event.target.files[0],
            imgName: event.target.files[0].name,
            imgType:'data'
        })
    }
    removeFile = () => {
        this.setState({
            img:null,
            imgName:'',
            imgType:"url"
        });
    }
    submitHandler = event => {
        event.preventDefault();
        console.log(event.target.className)
        event.target.className += " was-validated";
        this.add()
    };
    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
   
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('COUNTRIES')) return <Redirect to='/Home' />

    return (
        <div className="App">
            <MDBContainer> 
                <MDBRow>  
                    <MDBCol md="12">
                        <div className="infoCard">
                            <Card className="editCard">
                                <CardMedia
                                style={{height:"0px"}}
                                />
                                <CardHeader></CardHeader>
                                <div style={{flexDirection: this.props.isRTL?'row-reverse':'row',display: 'flex'}}>
                                    <div className="topIcon">
                                        <span class="material-icons" style={{color:'white',fontSize:23}}>flag</span>
                                    </div>
                                    <div className="tableTitle" style={{display:this.props.isRTL?'inline-block':'inline-block',marginTop:this.props.isRTL?'-60px':'-60px',
                                    marginLeft:this.props.isRTL?'0':'0',marginRight:this.props.isRTL?'0':'0',position:'relative'}}>
                                    <p>{allStrings.addCountry}</p>
                                    </div>
                                </div>
                                <CardContent style={{minHeight:"390px",outline:'none'}}>
                                    <MDBContainer>
                                        
                                        <MDBRow>
                                            <MDBCol md="12">
                                            <form
                                            className="needs-validation"
                                            onSubmit={this.submitHandler}
                                            noValidate
                                            >
                                                <div className="grey-text">
                                                
                                                <MDBRow>
                                                    <MDBCol md="6">
                                                        <div class="md-form">
                                                        <label for="form65" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.englishName}</label>
                                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z0-9\s]/g,'') }  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_en} type="text" id="form645" name="name_en" class="form-control" onChange={this.changeHandler} required  validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>
                                                    <MDBCol md="6">
                                                        <div class="md-form">
                                                        <label for="form56" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.arabicName}</label>
                                                        <input onInput={(e) =>e.currentTarget.value = e.currentTarget.value.replace(/^[A-Za-z0-9 ]+$/g,'') } style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.name_ar} type="text" id="form56" name="name_ar" class="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md="6">
                                                        <div class="md-form">
                                                        <label for="form6" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.countryCode}</label>
                                                        <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.countryCode} type="text" id="form6" name="countryCode" class="form-control" onChange={this.changeHandler} required  validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>
                                                    <MDBCol md="6">
                                                        <div class="md-form">
                                                        <label for="form5" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.isoCode}</label>
                                                        <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.isoCode} type="text" id="form5" name="isoCode" class="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md="6">
                                                        <div class="md-form">
                                                        <label for="form0" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.numbersCount}</label>
                                                        <input  style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.numbersCount} type="text" id="form0" name="numbersCount" class="form-control" onChange={this.changeHandler} required  validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>
                                                    <MDBCol md="6">
                                                        <div class="md-form">
                                                        <label for="form00" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.hint}</label>
                                                        <input style={{direction:this.props.isRTL?'rtl':'ltr',backgroundPosition: this.props.isRTL?'left calc(.375em + .1875rem) center':'right calc(.375em + .1875rem) center'}} value={this.state.hint} type="text" id="form00" name="hint" class="form-control" onChange={this.changeHandler} required validate/>
                                                        <div class="valid-feedback">{allStrings.correct}</div>
                                                        <div class="invalid-feedback">{allStrings.requiredInput}</div>
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md="12">
                                                        <div className="fileUpload2"> 
                                                            <input  key={this.state.img} type='file' className="upImgs2" onChange={this.fileChangedHandler} ></input>
                                                            <span className="uploadParent"><UploadOutlined style={{color: '#000',fontSize: '14px',position: 'relative',top: '-3px',marginRight: '2px'}}/><span className="uploadName">{allStrings.upload}</span></span>
                                                        </div>
                                                        <div className="preview">
                                                        {this.state.img&&
                                                        <div style={{display:"inline-block",width: '100%',border: '1px solid mediumaquamarine'}}>
                                                            <img crossorigin="anonymous"alt="" src={URL.createObjectURL(this.state.img)}  className="previewImg2"/>
                                                            <span className="imgName">{this.state.imgName}</span>
                                                            <MDBIcon icon="trash" className="mr-2 removeImg2"
                                                            onClick={() => this.removeFile()}  ></MDBIcon>
                                                        </div>
                                                        }
                                                        </div>
                                                        
                                                    </MDBCol>
                                                    
                                                </MDBRow>
                                                </div>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <div className="text-center">
                                                    <MDBBtn
                                                    type="submit"
                                                    rounded
                                                    className="btn-block z-depth-1a"
                                                    >
                                                    <MDBIcon icon="plus" className="mr-2" />
                                                        {allStrings.addCountry}          
                                                    </MDBBtn>
                                                
                                                </div>
                                            </form>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </CardContent>
                            </Card>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        
        </div> 
    )
  }
}


const mapToStateProps = (state) => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
};

export default withRouter(
    connect(mapToStateProps, mapDispatchToProps)(Addcountry)
);