import React from 'react';
import { connect } from 'react-redux'
import { Result } from 'antd';
import { MDBModal, MDBModalBody} from "mdbreact";
class ResultDialog extends React.Component {
  
  state = {
    modal:true
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
    window.location.href = '/Home'
  }
    render() { 
      return ( 
        <div style={{width:"100%"}}>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} centered>
              <MDBModalBody>
              <Result
                  status={this.props.type}
                  title={this.props.message}
                  subTitle={this.props.details}
                />
            </MDBModalBody>
          </MDBModal>
        </div>
      );
    }
  }

  const mapToStateProps = state => ({
   
  })
  
  const mapDispatchToProps = {
    
  }

  export default  connect(mapToStateProps,mapDispatchToProps)(ResultDialog);
